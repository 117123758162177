






































import { Component } from 'vue-property-decorator';
import { ClickEventArgs } from '@syncfusion/ej2-vue-navigations';
import CFMGridPage from '@/scripts/CFMGridPage'
import { Predicate } from '@syncfusion/ej2-data';
import SparePartDialog from './SparePartDialog.vue';
import SparePartDetailDialog from './SparePartDetailDialog.vue'

import * as swal from '@/helpers/swal'
import * as blockui from '@/helpers/block-ui'

import { CFMDTOSparePart, CFMModelSparePartView } from '@/scripts/services/ClientApiAuto'
import { L10n } from '@syncfusion/ej2-base';

@Component<SparePart>({
    components: {
        'spare-part-dialog': SparePartDialog,
        'spare-part-detail-dialog':SparePartDetailDialog
    }
})
export default class SparePart extends CFMGridPage<CFMModelSparePartView> {
    entityType = "SparePart";
    sorts:{field:string,direction:string}[] = [{ field:"SparePartId", direction:"Descending" }];
    //groupOptions= { columns: ['Name'] };

    isDetailDialogActive = false;

    created(){
        this.filterSettings = {type: 'Menu'};

        L10n.load({
                "tr": {
                    "grid": {
                        "FilterButton": "Filtrele",
                        "ClearButton": "Temizle"
                    }
                }
        });

    this.toolbar = [
        {
            text: 'Excel Export', tooltipText: 'Excel Export', prefixIcon: 'e-excelexport', id: 'ExcelExport'
        }, 
        {
            text: 'CSV Export', tooltipText: 'CSV Export', prefixIcon: 'e-csvexport', id: 'CSVExport'
        }, 
        {
            text: 'PDF Export', tooltipText: 'PDF Export', prefixIcon: 'e-pdfexport', id: 'PDFExport'
        }, 
        {
            text: this.translate('add'), tooltipText: this.translate('add'), prefixIcon: 'e-add', id: 'Add',
        }, 
        {
            text: this.translate('edit'), tooltipText: this.translate('edit'), prefixIcon: 'e-edit', id: 'Edit',
        },
        {
            text: this.translate('delete'), tooltipText: this.translate('delete'), prefixIcon: 'e-delete', id: 'Delete',
        },                
        {
            text: this.translate('hide'), tooltipText: this.translate('hide'), prefixIcon: 'e-eye-slash', id: 'Hide',
        },
        {
            text: this.translate('show'), tooltipText: this.translate('show'), prefixIcon: 'e-preview', id: 'Show',
        }         
        // {
        //     text: this.translate('task_id'), tooltipText: this.translate('task_id'), prefixIcon: 'e-icon-grightarrow', id: 'TaskId',
        // }
    ];                
    }
    
    async mounted(){
        this.dataStateChange(this.state);
    }
    refreshGrid(){
        this.dataStateChange(this.state);
    }

    rowDataBound(args: any) {
        if (Number(args.data['StockCount']) == 0) {
            args.row.classList.add('bold-red-bg');
            return;
        }
        else if (args.data['StockCount'] <= args.data['CriticalStockCount']) {
            args.row.classList.add('red-bg');
        }
        else if ((args.data['CriticalStockCount'] < args.data['StockCount']) && (args.data['StockCount'] <= (args.data['CriticalStockCount'] * 1.3))) {
            args.row.classList.add('orange-bg');
        }
    }        
    recordDoubleClick(args: any) {
         this.edit(args.rowData.SparePartId);
    }
    async delete(data: CFMDTOSparePart) {
        //@ts-ignore
        const e = await swal.check(this, data.Name);
        if (e.value) {
            blockui.pageBlock(this);
            // const  response = console.log(this.data);
            const response = await this.apiClient.sparePart.sparePartDeleteBySparePartIdAndWarehouseId({sparePartId: data.SparePartId, wareHouseId: data.WarehouseId});
            if (response.data.IsSuccess) {
                toastr.success(this.translate('delete_successfully'));
                this.refreshGrid();
            } else {
                const errorMessage = response.data.Message ? response.data.Message : "Error";
                toastr.error(errorMessage);
            }
            blockui.unBlockPage();
        }
    }
    async edit(id: number){
        this.selectedId = id;
        this.isDialogActive = true;
        await this.$nextTick();
        (<SparePartDialog>this.$refs.sparePartDialog).showDialog();
    }
    async add(){
        this.selectedId = null;
        this.isDialogActive = true;
        await this.$nextTick();
        (<SparePartDialog>this.$refs.sparePartDialog).showDialog();
    }
    async toolbarClick(args: ClickEventArgs) {
        var selectedRecords = (<any>this.$refs.grid).getSelectedRecords();
        if (args.item.id === 'Edit') {
            if(selectedRecords.length > 0){
                this.edit(selectedRecords[0].SparePartId);
            }
        }
        else if(args.item.id === 'Add'){
            this.add();
        }
        else if(args.item.id === 'Delete'){
            if(selectedRecords.length > 0){
                this.delete(selectedRecords[0]);
            }
        }
        else if(args.item.id === 'Hide'){ 
            this.entityState.where?.push(new Predicate('StockCount','greaterthan',0,false))
            this.dataStateChange(this.state);           
        }
        else if (args.item.id === 'Show') {
            if (this.entityState.where && this.state.where) {
                    this.entityState.where = this.entityState.where.filter(element => element.field !== 'StockCount');
                    this.state.where = this.state.where.filter(predicate => predicate.field !== 'StockCount');
                    this.dataStateChange(this.state);
            }
        }
        // else if(args.item.id === 'TaskId'){ 
        //     alert('Task Id Button');
        // }
        this.toolbarClickBase(args);
    }
}
