











































































































































































































import CFMDialog from '@/scripts/CFMDialog'
import {Component, Vue} from 'vue-property-decorator';
import upload from '@/components/shared/upload.vue';

import * as blockui from '@/helpers/block-ui'

import {required} from "vuelidate/lib/validators";

import {CFMDTOSparePart, CFMDTOSparePartWarehouse } from '@/scripts/services/ClientApiAuto';
import CFMMultiselect from '@/components/partials/CFMMultiselect.vue';
import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";
import { mixinz } from '@/scripts/global/mixinz';
import { AccordionComponent } from '@syncfusion/ej2-vue-navigations';

@Component<SparePartDialog>({
    mixins: [mixinz],
    components: {
            'cfm-multiselect': CFMMultiselect,
            'upload': upload,
            "ejs-dropdownlist": DropDownListComponent,
            'ejs-accordion': AccordionComponent,
    },
    validations() {
        return {
          sparePart:{
              Id: {required},
              SerialNumber: {required},
              SupplyDayCount:{required},
              UnitPrice:{required},
              UnitPriceCurrency:{required},
              CriticalStockCount: {required}
          },
          selectedSparePartType:{
            Id: {required},
            KindId: {required},
            CategoryId:{required},
            SubCategoryId: {required}
          },
          selectedModel: {
            ModelId: {required}
          },
          selectedBrand: {
            BrandId: {required}
          },
          selectedUnit: {
            UnitId: {required}
          }
        }
    },
})
export default class SparePartDialog extends CFMDialog {
    drag: boolean = false;
    isUploadBusy: boolean = false;
    stockCount: number = 0;

    sparePartDetail = {};
    sparePartDetailName = "";
    sparePartDetailKindName = "";
    sparePartDetailCategory = "";
    sparePartDetailSubCategory = "";
    sparePartDetailBrand = "";
    sparePartDetailModel = "";
    sparePartDetailSupplyDayCount = ""
    sparePartDetailCriticalStockCount = "";
    sparePartDetailUnit = "";
    sparePartDetailDescription = "";
    sparePartDetailWarehouses = [];
    sparePartDetailTransferWarehouseSelected = [];
    sparePartHistory = [];

    forAddWareHouseNew: any = {};
    forAddSerialNumber: String = "";
    forAddAmount: Number = 0;
    forAddDescription: String = "";

    addedSparePartList: any = []
    
    sparePart: CFMDTOSparePart = {Id: 0};
    wareHouseList : {Name:String, Id:Number}[] = [];
    brandList : {Name:String, Id:Number}[] = [];
    modelList : {Name:String, Id:Number}[] = [];
    spCategoryList : {Name:String, Id:Number}[] = [];
    spSubCategoryList : {Name:String, Id:Number}[] = [];
    spTypeList : {Text:String, Value:Number}[] = [];
    spKindList : {Name:String, Id:Number}[] = [];
    unitList : {Name:String, Id:Number}[] = [];

    selectedWareHouse: CFMDTOSparePart = {Id: 0};
    selectedSparePartType: CFMDTOSparePart = {Id: 0};
    selectedSparePartKind: CFMDTOSparePart = {Id: 0};
    selectedCategory: CFMDTOSparePart = {Id: 0};
    selectedSubCategory: CFMDTOSparePart = {Id: 0};
    selectedModel: CFMDTOSparePart = {Id: 0};
    selectedBrand: CFMDTOSparePart = {Id: 0};
    selectedUnit: CFMDTOSparePart = {Id: 0};

    multiSelectProps = {
        "close-on-select": false,
        "clear-on-select": false,
        "preserve-search": true,
    }

  get selectOptions() {
        return {
            searchable: true,
            placeholder: this.translate('please_select'),
            "select-label": this.translate('select_label'),
            "deselect-label": this.translate('deselect_label'),
            "selected-label": this.translate('selected_label'),
            "show-no-results": false,
        }
  }

    get selectedWareHouses() {
        return {};
    //   if (this.sparePart.SparePartWarehouses?.length == 0) {
    //       return null;
    //   } else {
    //       var a = this.sparePart.SparePartWarehouses?.map(x => {
    //           let selectedObj = this.wareHouseList.find(y => x.WarehouseId == y.Id);
    //           if (selectedObj) {
    //               return selectedObj;
    //           } else {
    //               return {};
    //           }
    //       });
    //   }
    }

    async created(){
      const promises = [
        this.getWareHoueses(),
        this.getBrands(),
        this.getModels(0),
        this.getSparePartCategories(),
        this.getSparePartSubCategories(),
        this.getSparePartTypes(),
        this.getSparePartKinds(),
        this.getUnits(),
      ];
      if(this.isUpdate){
        blockui.blockModal(this);

        const res = await this.apiClient.sparePart.sparePartGetById({id: <number>this.id});
        const history = await this.apiClient.sparePart.sparePartGetHistory({sparePartId:<number>this.id})
        
        //@ts-ignore
        this.sparePartHistory = history.data;

        this.sparePartDetail = res.data;
        //@ts-ignore
        this.sparePartDetailName = res.data.Name || "";
        this.selectedSparePartType = res.data;
        //@ts-ignore
        this.sparePartDetailKindName = res.data.Kind.Name;
        this.sparePartDetailCategory = res.data.Category?.Name || "";
        this.sparePartDetailSubCategory = res.data.SubCategory?.Name || "";
        this.sparePartDetailBrand = res.data.Brand?.Name || "";
        this.sparePartDetailModel = res.data.Model?.Name || "";
        this.sparePartDetailSupplyDayCount = res.data.SupplyDayCount?.toString() || "";
        this.sparePartDetailCriticalStockCount = res.data.CriticalStockCount?.toString() || "";
        this.sparePartDetailUnit = res.data.Unit?.Name || "";
        this.sparePartDetailDescription = res.data.Description || "";
        //@ts-ignore
        this.sparePartDetailWarehouses = res.data.SparePartWarehouses;



        this.sparePart = res.data;
        // if (this.sparePart.SparePartWarehouses) {
        //     if (this.sparePart.SparePartWarehouses.length>0) {
        //         for (let index = 0; index < this.sparePart.SparePartWarehouses.length; index++) {
        //             if (this.sparePart.SparePartWarehouses[index].StockCount) {
        //                 this.stockCount = this.sparePart.SparePartWarehouses[index].StockCount;
        //             }
        //         }
        //     }
        // }
        this.selectedBrand = res.data;
        this.selectedModel = res.data;
        this.selectedUnit = res.data;
        // if(this.sparePart.TypeId){
        //     this.getSparePartTypeById(this.sparePart.TypeId);
        // }
        if (this.sparePart.Model) {
            this.selectedModel['Model'] = this.sparePart.Model;
            if (this.sparePart.Brand) {
                this.selectedBrand['Brand'] = this.sparePart.Brand;
                if (this.sparePart.Unit) {
                    this.selectedUnit['Unit'] = this.sparePart.Unit;
                }
            }
        }
      }
      blockui.unBlockModal();
    }

    onBrandChanged(args:any){
        this.getModels(args.BrandId);
    }

    handleAddBtn() {
        this.addedSparePartList.push({
            wareHouse: {
                Type: this.forAddWareHouseNew.Type,
                Id: this.forAddWareHouseNew.Id
            },
            serialNumber: this.forAddSerialNumber,
            amount: this.forAddAmount,
            Description: this.forAddDescription
        })
    }

    async handleClickupdateStockCountBtn(index:number) {
        //@ts-ignore
        if(parseInt(document.getElementById(`stockCount${index}`).value) < 0) {
            toastr.error("Stok sayısı 0 dan küçük olamaz")
            return;
        }
        let data = {
                //@ts-ignore
                Id: this.sparePartDetailWarehouses[index].Id,
                //@ts-ignore
                SparePartId: this.id,
                //@ts-ignore
                WarehouseId: this.sparePartDetailWarehouses[index].WarehouseId,
                //@ts-ignore
                Warehouse: this.sparePartDetailWarehouses[index].Warehouse,
                //@ts-ignore
                StockCount: parseInt(document.getElementById(`stockCount${index}`).value),
                //@ts-ignore
                Description: document.getElementById(`description${index}`).value,
                //@ts-ignore
                SerialNumber: this.sparePartDetailWarehouses[index].SerialNumber
            }
        await this.apiClient.sparePart.sparePartSaveToWareHouse(data);
        toastr.success(this.translate('save_successfully'));
        this.close();
        this.saveSucceeded()
    }

    async handleClickTransfer(index:number){
        if(!this.sparePartDetailTransferWarehouseSelected[index]){
            toastr.error("Depo seçmelisiniz")
            return;
        }
        //@ts-ignore
        if(!parseInt(document.getElementById(`wareHouseTransferAmount${index}`).value) || parseInt(document.getElementById(`wareHouseTransferAmount${index}`).value) < 0){
            toastr.error("Stok sayısı 0 dan büyük olmalıdır.")
            return;
            //@ts-ignore
        }else if(parseInt(document.getElementById(`wareHouseTransferAmount${index}`).value) > parseInt(document.getElementById(`stockCount${index}`).value)){
            toastr.error("Transfer edilecek miktar depodaki miktardan büyük olamaz.")
            return;
        }
        let data = {
            SparePartId: this.id,
            //@ts-ignore
            SourceWarehouseId: this.sparePartDetailWarehouses[index].Warehouse.Id,
            //@ts-ignore
            DestinationWarehouseId: this.sparePartDetailTransferWarehouseSelected[index],
            //@ts-ignore
            SerialNumber: this.sparePartDetailWarehouses[index].SerialNumber,
            //@ts-ignore
            StockCount: parseInt(document.getElementById(`wareHouseTransferAmount${index}`).value)
        }
        await this.apiClient.sparePart.sparePartTransferWarehouse(data);
        toastr.success("Transfer edildi")
        this.close();
        this.saveSucceeded()
    }

    removeAddedSparePartListItem(index:any) {
        this.addedSparePartList.splice(index,1);
    }

    async handleClickSave() {
        let promises: any[] = [];
        //@ts-ignore
        this.addedSparePartList.forEach(item => {
            promises.push(this.apiClient.sparePart.sparePartSaveToWareHouse({
                //@ts-ignore
                SparePartId: this.selectedSparePartType.Value,
                WarehouseId: item.wareHouse.Id,
                Warehouse: item.wareHouse.Type,
                StockCount: parseInt(item.amount),
                SerialNumber: item.serialNumber,
                Description: item.Description
            }))
        })
        if (promises.length > 0) {
            console.log(promises)
            await Promise.all(promises)
            toastr.success(this.translate('save_successfully'));
            this.saveSucceeded();
            this.close();
        }
        else {
            toastr.error("Lütfen boş bırakmayınız!");
        }
    }

    removeWareHouse(warehouse: CFMDTOSparePartWarehouse) {
    //   if(!this.sparePart.SparePartWarehouses) {return;}
    //     let capIndex = this.sparePart.SparePartWarehouses.findIndex(x => x.WarehouseId == warehouse.Id);
    //     this.sparePart.SparePartWarehouses.splice(capIndex, 1);
    }

    selectWareHouse(warehouse: CFMDTOSparePartWarehouse) {
    //   if (!this.sparePart.SparePartWarehouses) {
    //       Vue.set(this.sparePart, 'SparePartWarehouses', []);
    //   }
    //   if (!warehouse.WarehouseId) {
    //     warehouse.WarehouseId = warehouse.Id;
    //     // @ts-ignore
    //     warehouse['Warehouse'] = {Name:warehouse.Name,Id:warehouse.Id, StockCount: this.stockCount}
    //   }
    //   (<CFMDTOSparePartWarehouse[]>this.sparePart.SparePartWarehouses).push(warehouse);
    }

    async getWareHoueses(){
        const response = await this.apiClient.warehouse.warehouseGetWareHouses();
        this.wareHouseList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }

    async getBrands(){
        const response = await this.apiClient.brand.brandGetBrands();
        this.brandList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getModels(brandId: number){
        const response = await this.apiClient.model.modelGetModels({brandId:brandId});
        this.modelList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getSparePartCategories(){
        const response = await this.apiClient.sparePartCategory.sparePartCategoryGetCategories();
        this.spCategoryList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getSparePartSubCategories(){
        const response = await this.apiClient.sparePartSubCategory.sparePartSubCategoryGetCategories();
        this.spSubCategoryList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getSparePartTypes(){
        // const response = await this.apiClient.sparePartType.sparePartTypeGetTypes();
        // this.spTypeList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
        const response = await this.apiClient.sparePart.sparePartGetComboSource();
        //@ts-ignore
        this.spTypeList = response.data.map(x => ({Text:<string>x.Text, Value:<number>x.Value}))
    }
    async getSparePartKinds(){
        const response = await this.apiClient.sparePartKind.sparePartKindGetKinds();
        this.spKindList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getUnits(){
        const response = await this.apiClient.unit.unitGetUnits();
        this.unitList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }

    async getSparePartTypeById(id: number){
        // const response = await this.apiClient.sparePartType.sparePartTypeGetById({id: id});
        // this.selectedSparePartType = response.data;
        // this.selectedSparePartType['Type'] = response.data;
        const response = await this.apiClient.sparePart.sparePartGetById({id})
        this.sparePartDetail = response.data;
        //@ts-ignore
        this.sparePartDetailKindName = response.data.Kind.Name;
        this.sparePartDetailCategory = response.data.Category?.Name || "";
        this.sparePartDetailSubCategory = response.data.SubCategory?.Name || "";
        this.sparePartDetailBrand = response.data.Brand?.Name || "";
        this.sparePartDetailModel = response.data.Model?.Name || "";
        this.sparePartDetailSupplyDayCount = response.data.SupplyDayCount?.toString() || "";
        this.sparePartDetailCriticalStockCount = response.data.CriticalStockCount?.toString() || "";
        this.sparePartDetailUnit = response.data.Unit?.Name || "";
        this.sparePartDetailDescription = response.data.Description || "";
    }

    async getSparePartHistory(id: number){
        const response = await this.apiClient.sparePart.sparePartGetHistory({sparePartId:id});
        //@ts-ignore
        this.sparePartHistory = response.data;
    }

    async onTypeChanged(args: any){
        this.getSparePartTypeById(args.Value);
        this.getSparePartHistory(args.Value);
    }

    async onWareHauseChange(args: any){
        console.log(args)
    }
    async onTransferWareHauseChange(args: any){
        //@ts-ignore
        this.sparePartDetailTransferWarehouseSelected[parseInt(args.element.id.split("-")[1])] = args.itemData.Id;
    }

    async onSubmit() {
    //   this.$v.$touch();
    //   if (this.$v.$invalid) {
    //       return;
    //   }
    //     // @ts-ignore
    //     for (let index = 0; index < this.sparePart.SparePartWarehouses.length; index++) {
    //         // @ts-ignore
    //         const element = this.sparePart.SparePartWarehouses[index];
    //         element.StockCount = this.stockCount;
    //         // @ts-ignore
    //         if (element.Id == element.WarehouseId) {
    //         // @ts-ignore
    //             this.sparePart.SparePartWarehouses[index].Id = 0;
    //         // @ts-ignore
    //         }
    //     }
    //   let formData = {
    //     Id: this.sparePart.Id,
    //     BrandId: this.selectedBrand.BrandId,
    //     ModelId: this.selectedModel.ModelId,
    //     SerialNumber: this.sparePart.SerialNumber,
    //     CategoryId: this.selectedSparePartType.CategoryId,
    //     SubCategoryId: this.selectedSparePartType.SubCategoryId,
    //     TypeId: this.selectedSparePartType.Id,
    //     KindId: this.selectedSparePartType.KindId,
    //     SupplyDayCount: this.sparePart.SupplyDayCount,
    //     UnitPrice: this.sparePart.UnitPrice,
    //     UnitPriceCurrency: this.sparePart.UnitPriceCurrency,
    //     UnitId: this.selectedUnit.UnitId,
    //     CriticalStockCount: this.sparePart.CriticalStockCount,
    //     SparePartWarehouses: this.sparePart.SparePartWarehouses ? this.sparePart.SparePartWarehouses  : [],
    //     Description:this.sparePart.Description
    //   };
    //   blockui.blockModal(this);
    //   try{
    //     const response  = await this.apiClient.sparePart.sparePartSave(formData);
    //     if (response.data.IsSuccess) {
    //       toastr.success(this.translate('save_successfully'));
    //       this.saveSucceeded();
    //       this.close();
    //     } else {
    //       const errorMessage = response.data.Message ? response.data.Message : "Error";
    //       toastr.error(errorMessage);
    //     }
    //   }
    //   catch(e){
    //     console.log("err",e)
    //     toastr.error(e);
    //   }
    //   finally{
    //     blockui.unBlockModal();
    //   }
    }
}
