import moment from 'moment';
import ExcelJS from 'exceljs';
import * as api from '../services/api';
import Notification from '../../components/partials/header-parts/Notification';
import * as dateFormat from '../../helpers/dateFormat';
import { mixinz } from '../global/mixinz';
import * as blockui from '../../helpers/block-ui';
import chart from './dashboard-charts/chart';
import { groupBy } from '../global/util';

export default {
  mixins: [mixinz, chart],
  data() {
    return {
      languages: {},
      campuses: [],
      campusList: {},
      selectedLanguage: '',
      selectedCampusId: 0,
      gapReportStartDate: moment().startOf('month').format('DD.MM.YYYY'),
      gapReportEndDate: moment().endOf('month').format('DD.MM.YYYY'),
      workOrderIncidentReportStartDate: moment().startOf('month').format('DD.MM.YYYY'),
      workOrderIncidentReportEndDate: moment().endOf('month').format('DD.MM.YYYY'),
      workOrderReportStartDate: moment().startOf('month').format('DD.MM.YYYY'),
      workOrderReportEndDate: moment().endOf('month').format('DD.MM.YYYY'),
      incidentReportStartDate: moment().startOf('month').format('DD.MM.YYYY'),
      incidentReportEndDate: moment().endOf('month').format('DD.MM.YYYY'),
      periodicMaintenanceReportStartDate: moment().startOf('month').format('DD.MM.YYYY'),
      periodicMaintenanceReportEndDate: moment().endOf('month').format('DD.MM.YYYY'),
      deviceHealthReportStartDate: moment().startOf('month').format('DD.MM.YYYY'),
      deviceHealthReportEndDate: moment().endOf('month').format('DD.MM.YYYY'),
      staffPerformanceReportStartDate: moment().startOf('month').format('DD.MM.YYYY'),
      staffPerformanceReportEndDate: moment().endOf('month').format('DD.MM.YYYY'),
      vendorPerformanceReportStartDate: moment().startOf('month').format('DD.MM.YYYY'),
      vendorPerformanceReportEndDate: moment().endOf('month').format('DD.MM.YYYY'),
      periodicMaintenanceReportData: [],
      allAssetReportData:[],
      vendorPerformanceReportData: [],
      weekIndex: 0,
      weekCount: 0,
      roundCount: 0,
      weeklyRoundStartDate: moment().startOf('month').format('DD.MM.YYYY'),
      weeklyRoundEndDate: moment().endOf('month').format('DD.MM.YYYY'),

      reportChartWidth: 700,
      reportChartHeight: 500,
      isChartProcessing: false,
    };
  },
  components: {
    notification: Notification,
  },
  methods: {
    sidebarToggle() {
    },
    logout: () => {
      window.location = '#/Logout';
    },
    changePassword() {
      $('#change_password_modal').modal({
        show: true,
        backdrop: 'static',
      });
    },
    setSelectedCampus(value) {
      const self = this;
      if (self.selectedCampusId !== value) {
        self.selectedCampusId = value;
        if (value) {
          localStorage.setItem('campus-id', self.campusList[value].Id);
          localStorage.setItem('district-id', self.campusList[value].DistrictId);
          localStorage.setItem('city-id', self.campusList[value].CityId);
          localStorage.setItem('country-id', self.campusList[value].CountryId);
          localStorage.setItem('continent-id', self.campusList[value].ContinentId);
        } else {
          localStorage.removeItem('campus-id');
          localStorage.removeItem('district-id');
          localStorage.removeItem('city-id');
          localStorage.removeItem('country-id');
          localStorage.removeItem('continent-id');
        }
      }
    },
    toggleFullScreen() {
      this.$eventHub.$emit('toggleFullScreen');
    },
    async selectDate(startDate, endDate) {
      const self = this;
      if (!startDate || !endDate) {
        toastr.error(self.translate('start_and_end_dates_are_required'));
        blockui.unBlockPage();
        return false;
      }
      if (moment(startDate, self.pickerDateFormat).toDate() > moment(endDate, self.pickerDateFormat).toDate()) {
        toastr.error(self.translate('end_date_cannot_be_before_start_date'));
        blockui.unBlockPage();
        return false;
      }
      return true;
    },
    fetchData(startDate, endDate) {
      return this.$globalApiClient.report.reportGenerateGapReport({startDate: moment(startDate, 'DD.MM.YYYY').format('YYYY-MM-DD'), endDate: moment(endDate, 'DD.MM.YYYY').format('YYYY-MM-DD')});
    },
    fetchRoundGapReportForPoints(deviceHealthReportStartDate, deviceHealthReportEndDate) {
      return this.$globalApiClient.report.reportGetGapReportForPoints({startDate: moment(deviceHealthReportStartDate, 'DD.MM.YYYY').format('YYYY-MM-DD'), endDate: moment(deviceHealthReportEndDate, 'DD.MM.YYYY').format('YYYY-MM-DD')});
    },

    minuteToDisplayFormat(minute) {
      return dateFormat.formatMinute(minute, this);
    },

    getRoundData(startDate, endDate) {
      return this.$globalApiClient.report.reportGetRoundDurationStaffReport({startDate: moment(startDate, 'DD.MM.YYYY').format('YYYY-MM-DD'), endDate: moment(endDate, 'DD.MM.YYYY').format('YYYY-MM-DD')});
    },
    formatDataForWeek(dataRound) {
      const startOfWeekOfStartDate = moment(this.weeklyRoundStartDate, 'DD.MM.YYYY').startOf('week').toDate();
      const endOfWeekOfEndDate = moment(this.weeklyRoundEndDate, 'DD.MM.YYYY').endOf('week').toDate();
      let cursorStartOfWeek = startOfWeekOfStartDate;
      const formattedData = [];
      while (cursorStartOfWeek <= endOfWeekOfEndDate) {
        formattedData.push(dataRound.filter((round) => {
          const roundDate = moment(round.StartedDate).toDate();
          const cursorEndOfWeek = moment(cursorStartOfWeek).endOf('week').toDate();
          if (roundDate >= cursorStartOfWeek && roundDate <= cursorEndOfWeek) {
            return true;
          }
          return false;
        }));
        cursorStartOfWeek = moment(cursorStartOfWeek).add(1, 'week').toDate();
      }
      const formattedWeekData = [];
      formattedData.map((array) => {
        if (array.length != 0) {
          formattedWeekData.push(array);
        }
      });
      this.weekCount = formattedWeekData.length;
      return formattedWeekData;
    },
    async generateReports(reportName, context, startDate, endDate, workbook) {
      const dateMoment = new Date();
      let excelWorkbook = workbook;
      if (workbook === undefined) {
        excelWorkbook = new ExcelJS.Workbook();
        excelWorkbook.creator = 'CFM';
        excelWorkbook.created = dateMoment;
        excelWorkbook.calcProperties.fullCalcOnLoad = true;
        excelWorkbook.views = [
          {
            x: 0,
            y: 0,
            width: 10000,
            height: 20000,
            firstSheet: 0,
            activeTab: 1,
            visibility: 'visible',
          },
        ];
        if (context != null) {
          context.forEach((contextItem) => {
            // Add Work Sheet
            let sheet = excelWorkbook.addWorksheet(contextItem.sheetName, {});
            sheet.columns = contextItem.columnName;
          
            // Add Data 
            contextItem.sheetData.forEach((row) => {
              sheet.addRow(row);
            });
          
          
            // Add Header Style
            sheet.eachRow((sheetRow, sheetRowNumber) => {
              if (sheetRowNumber == 1) {
                sheet.autoFilter = `${sheet.getColumn(1).letter + "1:" + sheet.getColumn(sheet.columns.length).letter + "1"}`;
              }

              sheetRow.eachCell((sheetCell, sheetColNumber) => {
                if (sheetRowNumber == 1) {
                  sheetCell.font = {
                    bold: true,
                  };
                
                  sheetCell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '0042a5f5' }
                  };
                }
              });

              sheetRow.commit();
            });
          });
        }
      }

      if (startDate != null && endDate != null) {
          excelWorkbook.xlsx.writeBuffer().then((buffer) => {
          this.saveByteArray(`${reportName + '_' + moment(dateMoment, 'DD.MM.YYYY').format('YYYYMMDDHHmm') + '_' + moment(startDate, 'DD.MM.YYYY').format('YYYYMMDD') + '-' + moment(endDate, 'DD.MM.YYYY').format('YYYYMMDD')}.xlsx`, buffer); // this.saveByteArray(`${reportName + '_' + moment().format('X')}.xlsx`, buffer);
        });
      }
      else {
          excelWorkbook.xlsx.writeBuffer().then((buffer) => {
          this.saveByteArray(`${reportName + '_' + moment(dateMoment, 'DD.MM.YYYY').format('YYYYMMDDHHmm')}.xlsx`, buffer); // this.saveByteArray(`${reportName + '_' + moment().format('X')}.xlsx`, buffer);
        });
      }
    },
    
    async generateRoundReport() {
      const self = this;
      blockui.pageBlock(self);
      if (!await self.selectDate(self.gapReportStartDate, self.gapReportEndDate)) return;
      const res = await this.$globalApiClient.report.reportGenerateGapReport({
        startDate: moment(self.gapReportStartDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
        endDate: moment(self.gapReportEndDate, 'DD.MM.YYYY').format('YYYY-MM-DD')
      });
      self.roundReportData = res.data;

      // Round Bilgileri Start
      const roundColumns = [
        { key: "Name", header: this.translate('round_name'), width: 15 },
        { key: "RoundCount", header: this.translate('how_many_times'), width: 15 },
        { key: "AddedFlagCount", header: this.translate('how_many_flags_have_been_added'), width: 15 },
        { key: "FoundInvalid", header: this.translate('number_of_invalid_answers_deviating_from_normal'), width: 15 },
      ];
    
      const roundList = self.roundReportData.Rounds.map((round) => {
        return {
          Name: round.Name,
          RoundCount: round.RoundCount,
          AddedFlagCount: round.AddedFlagCount,
          FoundInvalid: round.FoundInvalid,
        };
      });
      // Round Bilgileri End

      // Envanter Bilgileri Start
      const inventoryColumns = [
        { key: "DeviceName", header: this.translate('capacity_equipment'), width: 20 },
        { key: "ParentDeviceName", header: this.translate('device'), width: 20 },
        { key: "PointName", header: this.translate('point_name'), width: 15 },
        { key: "RoomName", header: this.translate('room'), width: 15 },
        { key: "ControlCount", header: this.translate('how_many_times_check'), width: 15 },
        { key: "InvalidPointCount", header: this.translate('how_many_times_abnormal_answer_received'), width: 15 },
        // { key: "Name", header: this.translate('inventory'), width: 15 },
        // { key: "ControlCount", header: this.translate('how_many_times_check'), width: 15 },
        // { key: "InvalidPointCount", header: this.translate('number_of_points_deviating_from_normal'), width: 15 },
        // { key: "MostInvalidAnsweredPointName", header: this.translate('name_of_the_point_with_the_most_deviation_from_normal'), width: 15 },
      ];
    
      const inventoryList = self.roundReportData.Inventories.map((x) => {
        return {
          DeviceName: x.DeviceName,
          ParentDeviceName: x.ParentDeviceName,
          PointName: x.PointName,
          RoomName: x.RoomName,
          ControlCount: x.ControlCount,
          InvalidPointCount: x.InvalidPointCount,
          // Name: x.Name,
          // ControlCount: x.ControlCount,
          // InvalidPointCount: x.InvalidPointCount,
          // MostInvalidAnsweredPointName: x.MostInvalidAnsweredPointName,
        };
      });
      // Envanter Bilgileri End

      // Personnel Bilgileri Start
      const personnelColumns = [
        { key: "Name", header: this.translate('staff'), width: 15 },
        { key: "FoundInvalid", header: this.translate('number_of_deviations_from_normal_found'), width: 15 },
        { key: "AddedFlagCount", header: this.translate('number_of_warning_flags_added'), width: 15 },
      ];
    
      const personnelList = self.roundReportData.Personnel.map((x) => {
        return {
          Name: x.Name,
          FoundInvalid: x.FoundInvalid,
          AddedFlagCount: x.AddedFlagCount,
        };
      });
      let context = [
        {
          sheetName: this.translate('round_information'),
          sheetData: roundList,
          columnName:roundColumns,
        },
        {
          sheetName: this.translate('inventory_information'),
          sheetData: inventoryList,
          columnName:inventoryColumns,
        },
        {
          sheetName: this.translate('staff_information'),
          sheetData: personnelList,
          columnName:personnelColumns,
        }
      ];
      // Personnel Bilgileri End
      await this.generateReports("GapReport", context, self.gapReportStartDate,self.gapReportEndDate);
      blockui.unBlockPage();
    },  

    async generateReportWeeklyRound() {
      const self = this;
      blockui.pageBlock(self);
      if (!await self.selectDate(self.weeklyRoundStartDate, self.weeklyRoundEndDate)) return;
      let data;
      const dataRound = (await self.getRoundData(self.weeklyRoundStartDate, self.weeklyRoundEndDate)).data;
      data = self.formatDataForWeek(dataRound);
    
      const WeeklyRoundColumns = [
        { key: "StartedDate", header: this.translate('date'), width: 15 },
        { key: "Name", header: this.translate('round_name'), width: 15 },
        { key: "SpentTimeMinutes", header: this.translate('schedule_duration'), width: 15 },
        { key: "AssignedUsers", header: this.translate('responsible'), width: 15 },
        { key: "WeekIndex", header: this.translate('week'), width: 15 },
      ];

      const WeeklyRoundData = data.map((dataItem) => {
        return dataItem.map((round) => ({
            StartedDate: round.StartedDate ? dateFormat.viewDateTimeFormat(round.StartedDate) : '',
            Name: round.Name,
            SpentTimeMinutes: round.SpentTimeMinutes,
            AssignedUsers: round.AssignedUsers,
            WeekIndex: `${data.indexOf(dataItem) + 1}. Hafta`,
        }));
      });

      let context = [
        {
          sheetName: 'Veri',
          sheetData: WeeklyRoundData.flat(),
          columnName:WeeklyRoundColumns,
        }
      ];
      await this.generateReports("WeeklyRoundReport", context, self.weeklyRoundStartDate,self.weeklyRoundEndDate);
      blockui.unBlockPage();
    },

    async generateWorkOrderIncidentReport() {
      const self = this;
      blockui.pageBlock(self);
      if (!await self.selectDate(self.workOrderIncidentReportStartDate, self.workOrderIncidentReportEndDate)) return;
      const res = await this.$globalApiClient.report.getWorkOrderIncidentReport({
        startDate: moment(self.workOrderIncidentReportStartDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
        endDate: moment(self.workOrderIncidentReportEndDate, 'DD.MM.YYYY').format('YYYY-MM-DD')
      });
      self.workOrderIncidentReportData = res.data;

      const reportColumns = [
        { key: "RoomName", header: this.translate('room'), width: 20 },
        { key: "InventoryId", header: this.translate('inventory_id'), width: 20 },
        { key: "InventoryCode", header: this.translate('inventory_code'), width: 20 },
        { key: "InventoryName", header: this.translate('inventory_name'), width: 20 },
        { key: "InfrastructureName", header: this.translate('infrastructure'), width: 20 },
        { key: "CapacityTypeName", header: this.translate('capacity_type'), width: 20 },
        { key: "DeviceSerialNumber", header: this.translate('serial_number'), width: 20 },
        { key: "DeviceBrandName", header: this.translate('brand'), width: 20 },
        { key: "DeviceModelName", header: this.translate('model'), width: 20 },
        { key: "OrderSourceName", header: this.translate('order_type'), width: 20 },
        { key: "OrderId", header: this.translate('order_id'), width: 20 },
        { key: "OrderStatusName", header: this.translate('order_status_report'), width: 20 },
        { key: "WorkCategoryName", header: this.translate('task_category'), width: 20 },
        { key: "WorkGroupName", header: this.translate('work_group'), width: 20 },
        { key: "PeriodName", header: this.translate('period'), width: 20 },
        { key: "OrderName", header: this.translate('order_name'), width: 20 },
        { key: "ContractName", header: this.translate('contract_name'), width: 20 },
        { key: "OrderPriority", header: this.translate('priority'), width: 20 },
        { key: "AssignedBy", header: this.translate('assigned_by'), width: 20 },
        { key: "ScheduledTime", header: this.translate('scheduled_date'), width: 20, style: { numFmt: 'dd/mm/yyyy HH:mm' } },
        { key: "ActualDate", header: this.translate('actual_date'), width: 20, style: { numFmt: 'dd/mm/yyyy HH:mm' } },
        { key: "Duration", header: this.translate('scheduled_duration_report'), width: 20 },
        { key: "ActualDuration", header: this.translate('actual_duration_report'), width: 20 },
        { key: "ApprovalBy", header: this.translate('sent_by_for_approval_report'), width: 20 },
        { key: "ApprovedBy", header: this.translate('confirmed_by_report'), width: 20 },
        { key: "HasFeedback", header: this.translate('has_feedback'), width: 20 }
      ];
    
      const reportRows = self.workOrderIncidentReportData.map((row) => {
        return {
          RoomName: row.RoomName,
          InventoryId: row.InventoryId,
          InventoryCode: row.InventoryCode,
          InventoryName: row.InventoryName,
          InfrastructureName: row.InfrastructureName,
          CapacityTypeName: row.CapacityTypeName,
          DeviceSerialNumber: row.DeviceSerialNumber,
          DeviceBrandName: row.DeviceBrandName,
          DeviceModelName: row.DeviceModelName,
          OrderSourceName: row.OrderSourceName,
          OrderId: row.OrderId,
          OrderStatusName: row.OrderStatusName,
          WorkCategoryName: row.WorkCategoryName,
          WorkGroupName: row.WorkGroupName,
          PeriodName: row.PeriodName,
          OrderName: row.OrderName,
          ContractName: row.ContractName,
          OrderPriority: row.OrderPriority,
          AssignedBy: row.AssignedBy,
          ScheduledTime: row.ScheduledTime ? dateFormat.viewDateTimeFormatTZ(row.ScheduledTime) : null, // row.ScheduledTime ? moment(row.ScheduledTime).format('LLL') : '',
          ActualDate: row.ActualDate ? dateFormat.viewDateTimeFormatTZ(row.ActualDate) : null, // row.ActualDate ? moment(row.ActualDate).format('d.MM.yyyy HH:mm') : '',
          Duration: row.Duration,
          ActualDuration: row.ActualDuration,
          ApprovalBy: row.ApprovalBy,
          ApprovedBy: row.ApprovedBy,
          HasFeedback: row.HasFeedback ? this.translate('yes') : this.translate('no')
        };
      });

      let context = [
        {
          sheetName: this.translate('work_order_incident_sheet'),
          sheetData: reportRows,
          columnName:reportColumns,
        }
      ];

      await this.generateReports(this.translate('work_order_incident_report_excel_name'), context, self.workOrderIncidentReportStartDate,self.workOrderIncidentReportEndDate);
      blockui.unBlockPage();
    }, 

    async generateWorkOrderReport() {
      const self = this;
      const selectedDate = self.translate('between_to').formatString(this.workOrderReportStartDate, this.workOrderReportEndDate);
      blockui.pageBlock(self);
      let workOrderReportData = null;
      if (!await self.selectDate(self.workOrderReportStartDate, self.workOrderReportEndDate)) return;
      await this.$globalApiClient.report.reportGetWorkOrderReport({startDate: moment(self.workOrderReportStartDate, 'DD.MM.YYYY').format('YYYY-MM-DD'), endDate: moment(self.workOrderReportEndDate, 'DD.MM.YYYY').format('YYYY-MM-DD')}).then( (res) => {
          workOrderReportData = res.data;
      });
      const getTable = ({ columns, data, params }) => {
        const header = columns.map((column) => ({
          fillColor: '#eeeeee',
          text: column.title,
          style: 'tableHeader',
          alignment: 'center',
        }));

        const body = data.map((row) => columns.map((column) => ({ text: row[column.field], style: 'tableField' })));

        const footer = columns.map((column) => ({
          text: column.sum ? column.sum(data.map((x) => x[column.field]), data) : '',
          style: 'tableFooter',
          fillColor: '#eeeeee',
        }));

        const tableContent = [
          header,
          ...body,
        ];

        const footerExist = footer.findIndex((x) => x.text) >= 0;
        if (footerExist) {
          tableContent.push(footer);
        }

        return {
          style: 'table',
          table: {
            widths: params.widths,
            headerRows: 1,
            body: tableContent,
          },
          layout: {
            hLineWidth(i, node) {
              return (i === 0 || i === node.table.body.length) ? 2 : 1;
            },
            vLineWidth(i, node) {
              return (i === 0 || i === node.table.widths.length) ? 2 : 1;
            },
            hLineColor(i, node) {
              return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
            },
            vLineColor(i, node) {
              return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
            },
          },
        };
      };

      const startTimeDeviatedWorkOrderTable = getTable({
        params: { widths: ['*', 'auto', '*', 'auto', '*', '*', '*'] },
        columns: [{
          title: 'Ekipman',
          field: 'EquipmentName',
        }, {
          title: 'ID',
          field: 'Id',
        }, {
          title: 'İş',
          field: 'Work',
        }, {
          title: 'Kategori',
          field: 'WorkCategory',
        }, {
          title: 'Planlanan Tarih',
          field: 'ScheduledStartDate',
        }, {
          title: 'Gerçeklesen Tarih',
          field: 'ScheduledEndDate',
        }, {
          title: 'Sapma Süresi',
          field: 'DeviationTime',
        }],
        data: workOrderReportData.MostDeviationStartDateOfWorkOrderList.map((workOrder, index) => ({
          EquipmentName: workOrder.DeviceName,
          Id: workOrder.Id,
          Work: workOrder.WorkName,
          WorkCategory: workOrder.WorkCategory,
          ScheduledStartDate: `${dateFormat.viewDateTimeFormat((workOrder.ScheduledTime))} ${workOrder.Duration > 0 ? `-${self.minuteToDisplayFormat(workOrder.Duration)}` : ''}`,
          ScheduledEndDate: `${dateFormat.viewDateTimeFormat((workOrder.StartedDate))} ${workOrder.SpentTimeMinutes > 0 ? `-${self.minuteToDisplayFormat(workOrder.SpentTimeMinutes)}` : ''} `,
          DeviationTime: self.minuteToDisplayFormat(moment(workOrder.StartedDate).diff(workOrder.ScheduledTime, 'minutes')),
        })),
      });

      const mostDeviationDurationOfWorkOrderList = getTable({
        params: { widths: ['*', 'auto', '*', 'auto', '*', '*', '*'] },
        header: 'simple text',
        columns: [{
          title: 'Ekipman',
          field: 'EquipmentName',
        }, {
          title: 'ID',
          field: 'Id',
        }, {
          title: 'İş',
          field: 'Work',
        }, {
          title: 'Kategori',
          field: 'WorkCategory',
        }, {
          title: 'Planlanan Tarih',
          field: 'ScheduledStartDate',
        }, {
          title: 'Gerçeklesen Tarih',
          field: 'ScheduledEndDate',
        }, {
          title: 'Sapma Süresi',
          field: 'DeviationTime',
        }],
        data: workOrderReportData.MostDeviationDurationOfWorkOrderList.map((workOrder) => ({
          EquipmentName: workOrder.DeviceName,
          Id: workOrder.Id,
          Work: workOrder.WorkName,
          WorkCategory: workOrder.WorkCategory,
          ScheduledStartDate: `${dateFormat.viewDateTimeFormat((workOrder.ScheduledTime))} ${workOrder.Duration > 0 ? `-${self.minuteToDisplayFormat(workOrder.Duration)}` : ''}`,
          ScheduledEndDate: `${dateFormat.viewDateTimeFormat((workOrder.StartedDate))} ${workOrder.SpentTimeMinutes > 0 ? `-${self.minuteToDisplayFormat(workOrder.SpentTimeMinutes)}` : ''} `,
          DeviationTime: self.minuteToDisplayFormat(workOrder.SpentTimeMinutes - workOrder.Duration),
        })),
      });

      const pmDeviationList = getTable({
        params: { widths: ['*', 'auto', 'auto', 'auto', 'auto'] },
        header: 'simple text',
        columns: [
          {
            title: 'Ekipman',
            field: 'EquipmentName',
            sum: (e) => `Toplam Envanter Sayısı: ${e.length}`,

          }, {
            title: 'ID',
            field: 'Id',
          }, {
            title: 'İş',
            field: 'Work',
          }, {
            title: 'Planlanan Tarih',
            field: 'ScheduledStartDate',
          }, {
            title: 'Gerçeklesen Tarih',
            field: 'ScheduledEndDate',
          },
        ],
        data: workOrderReportData.PmDeviationList.map((workOrder) => ({
          EquipmentName: workOrder.DeviceName,
          Id: workOrder.Id,
          Work: workOrder.WorkName,
          ScheduledStartDate: `${dateFormat.viewDateTimeFormat((workOrder.ScheduledTime))}  ${workOrder.Duration > 0 ? `-${self.minuteToDisplayFormat(workOrder.Duration)}` : ''}`,
          ScheduledEndDate: `${dateFormat.viewDateTimeFormat((workOrder.ScheduledEndDate))}  ${workOrder.SpentTimeMinutes > 0 ? `-${self.minuteToDisplayFormat(workOrder.SpentTimeMinutes)}` : ''} `,
        })),
      });

      const categoryWOCountListLabels = [];
      const categoryWOCountListDataset = [];
      const categoryWOCountListBC = [];

      workOrderReportData.CategoryWorkOrderCountList.forEach((dataItem, i) => {
        categoryWOCountListDataset.push(dataItem.WorkOrderCount);
        categoryWOCountListBC.push(self.selectCustomChartColor(i));
        categoryWOCountListLabels.push(`${dataItem.Name}(${dataItem.WorkOrderCount})`);
      });
      const categoryWorkOrderCountListChart = await self.getImageOfChart('pie', categoryWOCountListLabels, categoryWOCountListDataset, categoryWOCountListBC, 'Kategori vs. İş Emri Sayısı', 'report_chart_canvas', 700, 500);
      const categoryWODurationListDataset = [];
      const categoryWODurationListBC = [];
      const categoryWODurationListLabels = [];

      workOrderReportData.CategoryWorkOrderDurationList.forEach((dataItem, i) => {
        categoryWODurationListDataset.push(dataItem.SpentTimeMinutes);
        categoryWODurationListBC.push(self.selectCustomChartColor(i));
        categoryWODurationListLabels.push(`${dataItem.Name}(${self.minuteToDisplayFormat(dataItem.SpentTimeMinutes)})`);
      });

      const categoryWorkOrderDurationListChart = await self.getImageOfChart('pie', categoryWODurationListLabels, categoryWODurationListDataset, categoryWODurationListBC, 'Kategori vs. Harcanan Süre', 'report_chart_canvas', 700, 500);

      const scheduledDurations = [];
      const actualDurations = [];
      const scheduledVsActualLabels = [];
      workOrderReportData.ScheduledVsActualDurationList.forEach((dataItem) => {
        scheduledDurations.push(dataItem.ScheduledDurationTimeMinutes);
        actualDurations.push(dataItem.ActualDurationTimeMinutes);
        scheduledVsActualLabels.push(dataItem.Name);
      });
      // 'Planlanan', 'Gerçekleşen', scheduledDurations, actualDurations
      /* ---------------CategoryWorkOrderCountWithStatusList------------------*/
      const workOrderNames = [...new Set(workOrderReportData.CategoryWorkOrderCountWithStatusList.map((a) => a.Name))];
      const workOrderStatusLabels = [...new Set(workOrderReportData.CategoryWorkOrderCountWithStatusList.map((a) => a.WorkOrderStatus))];

      const workOrderGroupByStatus = groupBy(workOrderReportData.CategoryWorkOrderCountWithStatusList, (x) => x.WorkOrderStatus);
      const workOrderCountByStatus = [];
      for (const key of workOrderGroupByStatus.keys()) {
        const statusObject = workOrderGroupByStatus.get(key);
        workOrderCountByStatus.push({
          key,
          color: statusObject.find((x) => x.WorkOrderStatus == key) && statusObject.find((x) => x.WorkOrderStatus == key).ColorCode,
        });
      }
      const workOrderData = {
        labels: workOrderNames,
        datasets: workOrderCountByStatus.map((item, index) => {
          const data = workOrderNames.map((workOrderName) => {
            const categoryWorkOrderCount = workOrderReportData.CategoryWorkOrderCountWithStatusList.find((x) => x.Name == workOrderName && x.WorkOrderStatus == item.key);
            if (!categoryWorkOrderCount) {
              return '';
            }
            return categoryWorkOrderCount.WorkOrderCount;
          });
          return {
            label: item.key,
            backgroundColor: item.color,
            borderColor: item.color,
            data,
            fill: false,
          };
        }),
      };
      const categoryWorkOrderCountWithStatusList = await self.getMultipleAxisChart2('İş Emri Kategorileri vs Durumları', workOrderData, false, 1400, 800);

      /* ---------------scheduledVsActualDurationList------------------*/
      const scheduledActualName = [...new Set(workOrderReportData.ScheduledVsActualDurationList.map((a) => a.Name))];
      const scheduledActualCategory = ['Planlanan', 'Gerçekleşen'];
      const scheduledDurationMinutes = workOrderReportData.ScheduledVsActualDurationList.map((x, index) => x.ScheduledDurationTimeMinutes);
      const actualDurationTimeMinutes = workOrderReportData.ScheduledVsActualDurationList.map((x, index) => x.ActualDurationTimeMinutes);
      const scheduledActualGroupByName = groupBy(workOrderReportData.ScheduledVsActualDurationList, (x) => x.Name);

      const labels = scheduledActualName;
      const dataset = scheduledActualCategory.map((x, index) => {
        const colorIndex = Math.floor(Math.random() * 11);
        return {
          label: x,
          data: index === 0 && scheduledDurationMinutes ? scheduledDurationMinutes : index === 1 && actualDurationTimeMinutes,
          backgroundColor: this.selectCustomChartColor(colorIndex),
          borderColor: this.selectCustomChartColor(colorIndex),
          fill: false,
        };
      });

      const scheduledActualListData = {
        labels,
        datasets: dataset,
      };
      const scheduledVsActualDurationList = await self.getMultipleAxisChart2('Planlanan ve Gerçekleşen Süreler', scheduledActualListData, true, 1400, 800);

      const printObj = {
        header(currentPage, pageCount) {
          return [
            {
              margin: [5, 20, 10, 30],
              columns: [
                {
                  text: `${selectedDate} İş Emri Raporu`,
                  fontSize: 14,
                  color: '#2f5596',
                  alignment: 'left',
                  width: 'auto',
                  margin: [0, 0, 90, 0],
                }, {
                  text: moment().format('L'),
                  width: '*',
                  fontSize: 14,
                  color: '#2f5596',
                }, {
                  text: `${currentPage.toString()} / ${pageCount}`,
                  fontSize: 7,
                  width: '*',
                  alignment: 'right',
                },
              ],
            }];
        },
        content: [
          /* {
                         alignment: 'justify',
                         style: 'header',
                         margin: [5, 10, 20, 10],
                         columns: [
                             {
                                 text: selectedDate + ' Tarihleri Arası İş Emri Raporu',
                                 fontSize: 14,
                                 color: "#2f5596",
                                 alignment: 'left',
                                 width: "auto",
                                 margin: [0, 0, 50, 0],
                             }, {
                                 text: moment().format("L"),
                                 width: "*",
                                 fontSize: 14,
                                 color: "#2f5596",
                             }, {
                                 text: currentPage.toString() + ' / ' + pageCount,
                                 fontSize: 7,
                                 width: "*",
                                 alignment: 'right',
                             }
                         ]
                     }, */
          {
            style: 'infoContainer',
            table: {
              headerRows: 0,
              widths: ['*'],
              body: [
                [`${workOrderReportData.WorkOrderCountSummary.CapacityEquipmentCount} envanterden ${workOrderReportData.WorkOrderCountSummary.DevicePreventiveMaintenanceCount} adetine toplam ${workOrderReportData.WorkOrderCountSummary.PreventiveMaintenanceCount} önleyici bakım yapıldı.`],
              ],
            },
          },
          {
            margin: [0, 20, 0, 20],
            columnGap: 2,
            columns: [
              { image: categoryWorkOrderCountListChart, width: 250 },
              { image: categoryWorkOrderDurationListChart, width: 250 },
            ],
          },
          {
            margin: [0, 50, 0, 20],
            columnGap: 1,
            columns: [
              { image: categoryWorkOrderCountWithStatusList, width: 500 },
            ],
          },
          {
            margin: [0, 50, 0, 20],
            columnGap: 1,
            columns: [
              { image: scheduledVsActualDurationList, width: 500 },
            ],
          },
          {
            id: 'startTimeDeviatedWorkOrderTable',
            stack: [
              self.tableContentHeader('infoContainer', 'info', 'Plandan En Çok Başlangıç Saati Sapan 10 İş Emri'),
              startTimeDeviatedWorkOrderTable,
            ],
          },
          {
            id: 'mostDeviationDurationOfWorkOrderList',
            stack: [
              self.tableContentHeader('infoContainer', 'info', 'Plandan En Çok Süresi Sapan 10 İş Emri'),
              mostDeviationDurationOfWorkOrderList,
            ],
          },
          {
            id: 'pmDeviationList',
            stack: [
              self.tableContentHeader('infoContainer', 'info', 'Önleyici Bakım Yapılan Envarteler'),
              pmDeviationList,
            ],
          },
        ],
        styles: {
          header: {
            fontSize: 14,
            color: '#2f5596',
          },
          infoContainer: {
            margin: [0, 5, 0, 5],
          },
          info: {
            fontSize: 14,
            fillColor: '#2f5596',
            color: '#ffffff',
          },
          table: {
            margin: [0, 42, 0, 22],
            width: [],
          },
          tableHeader: {
            bold: true,
            fontSize: 10,
            color: '#243951',
          },
          tableFooter: {
            bold: true,
            fontSize: 10,
            color: '#243951',
          },
        },
        pageSize: 'A4',
        pageBreakBefore(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
          if (currentNode.id === 'startTimeDeviatedWorkOrderTable' || currentNode.id === 'mostDeviationDurationOfWorkOrderList' || currentNode.id === 'pmDeviationList') {
            return currentNode.pageNumbers.length > 1;
          }
          return false;
        },
      };

      blockui.unBlockPage();
      pdfMake.createPdf(printObj).print();
    }, 

    async generateIncidentReport() {
      const self = this;
      const selectedDate = self.translate('between_to').formatString(this.incidentReportStartDate, this.incidentReportEndDate);
      blockui.pageBlock(self);
      let incidentReportData = null;
      if (!await self.selectDate(self.incidentReportStartDate, self.incidentReportEndDate)) return;
      await this.$globalApiClient.report.reportGetIncidentReport({startDate: moment(self.incidentReportStartDate, 'DD.MM.YYYY').format('YYYY-MM-DD'), endDate: moment(self.incidentReportEndDate, 'DD.MM.YYYY').format('YYYY-MM-DD')}).then( (res) => {
          incidentReportData = res.data;
      });

      const getTable = ({ columns, data, params }) => {
        const header = columns.map((column) => ({
          fillColor: '#eeeeee',
          text: column.title,
          style: 'tableHeader',
          alignment: 'center',
        }));

        const body = data.map((row) => columns.map((column) => ({ text: row[column.field], style: 'tableField' })));

        const footer = columns.map((column) => ({
          text: column.sum ? column.sum(data.map((x) => x[column.field]), data) : '',
          style: 'tableFooter',
          fillColor: '#eeeeee',
        }));

        const tableContent = [
          header,
          ...body,
        ];

        const footerExist = footer.findIndex((x) => x.text) >= 0;
        if (footerExist) {
          tableContent.push(footer);
        }

        return {
          style: 'table',
          table: {
            widths: params.widths,
            headerRows: 1,
            body: tableContent,
          },
          layout: {
            hLineWidth(i, node) {
              return (i === 0 || i === node.table.body.length) ? 2 : 1;
            },
            vLineWidth(i, node) {
              return (i === 0 || i === node.table.widths.length) ? 2 : 1;
            },
            hLineColor(i, node) {
              return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
            },
            vLineColor(i, node) {
              return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
            },
          },
        };
      };

      const startTimeDeviatedIncidentTable = getTable({
        params: { widths: ['*', 'auto', '*', 'auto', '*', '*', '*', '*'] },
        columns: [{
          title: 'Ekipman / Oda',
          field: 'EquipmentRoomName',
        }, {
          title: 'ID',
          field: 'Id',
        }, {
          title: 'Olay',
          field: 'Name',
        }, {
          title: 'Öncelik',
          field: 'PriorityName',
        }, {
          title: 'Kategori',
          field: 'CategoryName',
        }, {
          title: 'Planlanan Müdahale Tarihi',
          field: 'ScheduledTime',
        }, {
          title: 'Gerçekleşen Müdahale Tarihi',
          field: 'StartedDate',
        }, {
          title: 'Geciken Müdahale Süresi',
          field: 'StartTimeDeviation',
        }],
        data: incidentReportData.MostDeviationStartDateOfIncidentList.map((workOrder, index) => ({
          EquipmentRoomName: [workOrder.RoomName, workOrder.DeviceName].filter((x) => x).join('-'),
          Id: workOrder.Id,
          Name: workOrder.Name,
          PriorityName: workOrder.PriorityName,
          CategoryName: workOrder.CategoryName,
          ScheduledTime: dateFormat.viewDateTimeFormat(workOrder.ScheduledTime),
          StartedDate: dateFormat.viewDateTimeFormat(workOrder.StartedDate),
          StartTimeDeviation: self.minuteToDisplayFormat(workOrder.StartTimeDeviation),
        })),
      });

      const mostDeviationDurationOfIncidentList = getTable({
        params: { widths: ['*', 'auto', '*', 'auto', '*', '*', '*', '*', '*', '*'] },
        header: 'simple text',
        columns: [{
          title: 'Ekipman / Oda',
          field: 'EquipmentRoomName',
        }, {
          title: 'ID',
          field: 'Id',
        }, {
          title: 'Olay',
          field: 'Name',
        }, {
          title: 'Öncelik',
          field: 'PriorityName',
        }, {
          title: 'Kategori',
          field: 'CategoryName',
        }, {
          title: 'Planlanan Müdahale Tarihi',
          field: 'ScheduledTime',
        }, {
          title: 'Gerçekleşen Müdahale Tarihi',
          field: 'StartedDate',
        }, {
          title: 'Taahhüt Edilen Çözüm Süresi',
          field: 'Duration',
        }, {
          title: 'Gerçekleşen Çözüm Süresi',
          field: 'SpentTimeMinutes',
        }, {
          title: 'Geciken Çözüm Süresi',
          field: 'Deviation',
        }],
        data: incidentReportData.MostDeviationDurationOfIncidentList.map((workOrder) => ({
          EquipmentRoomName: [workOrder.RoomName, workOrder.DeviceName].filter((x) => x).join('-'),
          Id: workOrder.Id,
          Name: workOrder.Name,
          PriorityName: workOrder.PriorityName,
          CategoryName: workOrder.CategoryName,
          ScheduledTime: dateFormat.viewDateTimeFormat(workOrder.ScheduledTime),
          StartedDate: dateFormat.viewDateTimeFormat(workOrder.StartedDate),
          Duration: self.minuteToDisplayFormat(workOrder.Duration),
          SpentTimeMinutes: self.minuteToDisplayFormat(workOrder.SpentTimeMinutes),
          Deviation: self.minuteToDisplayFormat(workOrder.Deviation),
        })),
      });

      const categoryWOCountListLabels = [];
      const categoryWOCountListDataset = [];
      const categoryWOCountListBC = [];

      incidentReportData.CategoryIncidentCountList.forEach((dataItem, i) => {
        categoryWOCountListDataset.push(dataItem.IncidentCount);
        categoryWOCountListBC.push(self.selectCustomChartColor(i));
        categoryWOCountListLabels.push(`${dataItem.Name}(${dataItem.IncidentCount})`);
      });
      const categoryIncidentCountListChart = await self.getImageOfChart('pie', categoryWOCountListLabels, categoryWOCountListDataset, categoryWOCountListBC, 'Kategori vs. Olay Sayısı', 'report_chart_canvas', 700, 500);
      const categoryWODurationListDataset = [];
      const categoryWODurationListBC = [];
      const categoryWODurationListLabels = [];

      incidentReportData.CategoryIncidentDurationList.forEach((dataItem, i) => {
        categoryWODurationListDataset.push(dataItem.SpentTimeMinutes);
        categoryWODurationListBC.push(self.selectCustomChartColor(i));
        categoryWODurationListLabels.push(`${dataItem.Name}(${self.minuteToDisplayFormat(dataItem.SpentTimeMinutes)})`);
      });

      const categoryIncidentDurationListChart = await self.getImageOfChart('pie', categoryWODurationListLabels, categoryWODurationListDataset, categoryWODurationListBC, 'Kategori vs. Harcanan Süre', 'report_chart_canvas', 700, 500);

      /* ---------------CategoryIncidentCountWithStatusList------------------*/
      const workOrderNames = [...new Set(incidentReportData.CategoryIncidentCountWithStatusList.map((a) => a.Name))];
      const workOrderStatusLabels = [...new Set(incidentReportData.CategoryIncidentCountWithStatusList.map((a) => a.WorkOrderStatus))];

      const workOrderGroupByStatus = groupBy(incidentReportData.CategoryIncidentCountWithStatusList, (x) => x.WorkOrderStatus);
      const workOrderCountByStatus = [];
      for (const key of workOrderGroupByStatus.keys()) {
        const statusObject = workOrderGroupByStatus.get(key);
        workOrderCountByStatus.push({
          key,
          color: statusObject.find((x) => x.WorkOrderStatus == key) && statusObject.find((x) => x.WorkOrderStatus == key).ColorCode,
        });
      }
      const workOrderData = {
        labels: workOrderNames,
        datasets: workOrderCountByStatus.map((item, index) => {
          const data = workOrderNames.map((workOrderName) => {
            const categoryWorkOrderCount = incidentReportData.CategoryIncidentCountWithStatusList.find((x) => x.Name == workOrderName && x.WorkOrderStatus == item.key);
            if (!categoryWorkOrderCount) {
              return '';
            }
            return categoryWorkOrderCount.IncidentCount;
          });
          return {
            label: item.key,
            backgroundColor: item.color,
            borderColor: item.color,
            data,
            fill: false,
          };
        }),
      };
      const categoryIncidentCountWithStatusList = await self.getMultipleAxisChart2('Olay Kategorileri vs Durumları', workOrderData, false, 1400, 800);

      const printObj = {
        header(currentPage, pageCount) {
          return [
            {
              margin: [5, 20, 10, 30],
              columns: [
                {
                  text: `${selectedDate} Olay Raporu`,
                  fontSize: 14,
                  color: '#2f5596',
                  alignment: 'left',
                  width: 'auto',
                  margin: [0, 0, 90, 0],
                }, {
                  text: moment().format('L'),
                  width: '*',
                  fontSize: 14,
                  color: '#2f5596',
                }, {
                  text: `${currentPage.toString()} / ${pageCount}`,
                  fontSize: 7,
                  width: '*',
                  alignment: 'right',
                },
              ],
            }];
        },
        content: [
          {
            margin: [0, 20, 0, 20],
            columnGap: 2,
            columns: [
              { image: categoryIncidentCountListChart, width: 250 },
              { image: categoryIncidentDurationListChart, width: 250 },
            ],
          },
          {
            margin: [0, 50, 0, 20],
            columnGap: 1,
            columns: [
              { image: categoryIncidentCountWithStatusList, width: 500 },
            ],
          },
          {
            id: 'startTimeDeviatedIncidentTable',
            stack: [
              self.tableContentHeader('infoContainer', 'info', 'Müdahale Süresi En Çok Geciken 10 Olay'),
              startTimeDeviatedIncidentTable,
            ],
          },
          {
            id: 'mostDeviationDurationOfIncidentList',
            stack: [
              self.tableContentHeader('infoContainer', 'info', 'Çözüm Süresi En Çok Geciken 10 Olay'),
              mostDeviationDurationOfIncidentList,
            ],
          },
        ],
        styles: {
          header: {
            fontSize: 14,
            color: '#2f5596',
          },
          infoContainer: {
            margin: [0, 5, 0, 5],
          },
          info: {
            fontSize: 14,
            fillColor: '#2f5596',
            color: '#ffffff',
          },
          table: {
            margin: [0, 42, 0, 22],
            width: [],
            fontSize: 7,
          },
          tableHeader: {
            bold: true,
            fontSize: 7,
            color: '#243951',
          },
          tableFooter: {
            bold: true,
            fontSize: 7,
            color: '#243951',
          },
        },
        pageSize: 'A4',
        pageBreakBefore(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
          if (currentNode.id === 'startTimeDeviatedIncidentTable' || currentNode.id === 'mostDeviationDurationOfIncidentList') {
            return currentNode.pageNumbers.length > 1;
          }
          return false;
        },
      };

      blockui.unBlockPage();
      pdfMake.createPdf(printObj).print();
    },

    tableContentHeader(style, bodyStyle, text) {
      return {
        style,
        table: {
          widths: ['*'],
          headerRows: 0,
          body: [
            [{
              style: bodyStyle,
              text,
            }],
          ],
        },
      };
    },
    formatToGroupData(datas) {
      const groupedData = groupBy(datas, (data) => data.Id);
      const formattedData = [];
      let totalInvalidPointCount = 0;
      for (const key of groupedData.keys()) {
        const data = groupedData.get(key);
        let sumInvalidPointCount = 0;
        let maxInvalidPointCount = 0;
        let controlCountSum = 0;
        for (let i = 0; i < data.length; i++) {
          sumInvalidPointCount += data[i].InvalidPointCount;
          controlCountSum += data[i].ControlCount;
          maxInvalidPointCount = data[i].InvalidPointCount > maxInvalidPointCount ? data[i].InvalidPointCount : maxInvalidPointCount;
        }
        formattedData.push({
          key,
          data,
          sumInvalidPointCount,
          maxInvalidPointCount,
          controlCountSum,
        });
        totalInvalidPointCount += sumInvalidPointCount;
      }
      return {
        totalInvalidPointCount,
        formattedData: formattedData.sort((x, y) => y.sumInvalidPointCount - x.sumInvalidPointCount),
      };
    },
    async generateStaffPerformanceReport() {
      const self = this;
      blockui.pageBlock(self);
      if (!await self.selectDate(self.staffPerformanceReportStartDate, self.staffPerformanceReportEndDate)) return;
        const res = await this.$globalApiClient.report.reportGetStaffPerformanceReport({
          startDate: moment(self.staffPerformanceReportStartDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
          endDate: moment(self.staffPerformanceReportEndDate, 'DD.MM.YYYY').format('YYYY-MM-DD')
        });
      self.staffPerformanceData = res.data;
      const StaffPerformanceColumn = [
        { key: "StaffName", header: "Personel Adı", width: 25 },
        { key: "OrderCount", header: "İş Emri Sayısı", width: 15 },
        { key: "SpentTime", header: "Harcanan Süre(Dakika)", width: 10 },
        { key: "InvalidAnswerCount", header: "Geçersiz Yanıt Sayısı", width: 20 },
        { key: "FlagCount", header: "Bayrak Sayısı", width: 15 },
      ];
      const preventativeMaintenenceData = self.staffPerformanceData.PreventativeMaintenenceList.map((x) => {
        return {
          StaffName: x.StaffName,
          OrderCount: x.OrderCount,
          SpentTime: x.SpentTime,
          InvalidAnswerCount: x.InvalidAnswerCount,
          FlagCount: x.FlagCount,
        };
      });
      const rowsRoundData = self.staffPerformanceData.RoundList.map((x) => {
        return {
          StaffName: x.StaffName,
          OrderCount: x.OrderCount,
          SpentTime: x.SpentTime,
          InvalidAnswerCount: x.InvalidAnswerCount,
          FlagCount: x.FlagCount,
        };
      });
      const rowsIncidentData = self.staffPerformanceData.IncidentList.map((x) => {
        return {
          StaffName: x.StaffName,
          OrderCount: x.OrderCount,
          SpentTime: x.SpentTime,
          InvalidAnswerCount: x.InvalidAnswerCount,
          FlagCount: x.FlagCount,
        };
      });

      let context = [
        {
          sheetName: 'Önleyici Bakım Listesi',
          sheetData: preventativeMaintenenceData,
          columnName:StaffPerformanceColumn,
        },
        {
          sheetName: 'Round Listesi',
          sheetData: rowsRoundData,
          columnName:StaffPerformanceColumn,
        },
        {
          sheetName: 'Olay Listesi',
          sheetData: rowsIncidentData,
          columnName:StaffPerformanceColumn,
        },
      ];
      await this.generateReports("StaffPerformanceReport", context, self.staffPerformanceReportStartDate,self.staffPerformanceReportEndDate);
      blockui.unBlockPage();
    },    
    async generatePointFailureAnalysis() {
      const self = this;
      blockui.pageBlock(self);

      if (!await self.selectDate(self.deviceHealthReportStartDate, self.deviceHealthReportEndDate)) return;
    
      await this.$globalApiClient.report.reportGetGapReportForPoints({
        startDate: moment(self.deviceHealthReportStartDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
        endDate: moment(self.deviceHealthReportEndDate, 'DD.MM.YYYY').format('YYYY-MM-DD')
      }).then((res) => {
        self.vendorPerformanceReportData = res.data;
      });
    
      const PointFailureAnalysisColumns = [
        { key: "DeviceName", header: this.translate('inventory'), width: 10 },
        { key: "PointName", header: this.translate('point_name'), width: 15 },
        { key: "ControlCount", header: this.translate('how_many_times_check'), width: 15 },
        { key: "InvalidPointCount", header: this.translate('how_many_times_abnormal_answer_received'), width: 20 },
      ];

      const PointFailureAnalysisData = self.vendorPerformanceReportData.map((x) => {
        return {
          DeviceName: x.DeviceName,
          PointName: x.PointName,
          ControlCount: x.ControlCount,
          InvalidPointCount: x.InvalidPointCount,
        };
      });
    
      let context = [
        {
          sheetName: 'Veri',
          sheetData: PointFailureAnalysisData,
          columnName:PointFailureAnalysisColumns,
        }
      ];

      await this.generateReports("DeviceHealthReport", context);
      blockui.unBlockPage();
    },  
    selectCustomChartColor(index) {
      const chartColors = [
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        'rgb(153, 102, 255)',
        'rgb(94,168,158)',
        'rgb(102,255,178)',
        'rgb(255,178,102)',
        'rgb(0,76,103)',
        'rgb(164,69,156)'];
      return chartColors[index];
    },
    getImageOfChart(type, labels, data, colors, chartTitle, id, width, height) {
      const self = this;
      return new Promise((resolve, reject) => {
        try {
          this.reportChartWidth = width;
          this.reportChartHeight = height;
          this.isChartProcessing = true;
          self.$nextTick(() => {
            const ctx = document.getElementById(id).getContext('2d');
            const myChart = new Chart(ctx, {
              type,
              responsive: false,
              data: {
                labels,
                datasets: [{
                  data,
                  backgroundColor: colors,
                  borderColor: colors,
                }],
              },
              options: {
                animation: {
                  onComplete: () => {
                    const image = myChart.toBase64Image();
                    myChart.destroy();
                    self.isChartProcessing = false;
                    resolve(image);
                  },
                },
                title: {
                  display: true,
                  text: chartTitle,
                  fontSize: 40,
                  // padding:52,
                },
                hover: {
                  animationDuration: 0,
                  mode: 'nearest',
                  intersect: true,
                },
                legend: {
                  margin: 0,
                  padding: 0,
                  position: 'bottom',
                  labels: {
                    // padding: 35,
                    usePointStyle: true,
                    fontSize: 24,

                  },
                },
                tooltips: {
                  enabled: false,
                },
                layout: {
                  padding: {
                    left: 0,
                    right: 0,
                    top: 20,
                    bottom: 20,
                  },
                },
                plugins: [{
                  paddingBelowLegends: false,
                }],
                /*    legend: {
                                        verticalAlign: "center",
                                        horizontalAlign: 'center',
                                        position: 'right',
                                        labels: {
                                            fontSize: 52,
                                            usePointStyle: true,
                                        },
                                    }, */
                //  scales: {scaleLabel: {pointLabelFontSize: 35}}

                /* xAxes: [{
                                     beginAtZero: false,
                                     ticks: {
                                         autoSkip: false
                                     }
                                 }] */

              },
            });
          });
        } catch (e) {
          reject(e);
        }
      });
    },
    getMultipleAxisChart2(title, data, dateFormat = false, width, height) {
      const self = this;
      return new Promise((resolve, reject) => {
        try {
          this.reportChartWidth = width;
          this.reportChartHeight = height;
          this.isChartProcessing = true;
          self.$nextTick(() => {
            // document.body.innerHTML = '<canvas style="display: none;position: relative; height:88vh; width:88vw" id="myChart"></canvas>';
            const barChartValues = function (chart) {
              // render the value of the chart above the bar
              const { ctx } = chart;
              ctx.font = Chart.helpers.fontString(20, 'normal', Chart.defaults.global.defaultFontFamily);
              ctx.fillStyle = chart.config.options.defaultFontColor;
              ctx.textAlign = 'center';
              ctx.textBaseline = 'bottom';
              chart.data.datasets.forEach((dataset) => {
                for (let i = 0; i < dataset.data.length; i++) {
                  if (dataset.hidden === true && dataset._meta[Object.keys(dataset._meta)[0]].hidden !== false) {
                    continue;
                  }
                  const model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
                  if (dataset.data[i] !== null) {
                    ctx.fillText(dateFormat ? self.minuteToDisplayFormat(dataset.data[i]) : dataset.data[i], model.x - 1, model.y - 5);
                  }
                }
              });
            };
            Chart.plugins.register({
              id: 'paddingBelowLegends',
              beforeInit(chart, options) {
                chart.legend.afterFit = function () {
                  this.height += 70;
                };
              },
            });
            const ctx = document.getElementById('report_chart_canvas').getContext('2d');
            const myChart = new Chart(ctx, {
              type: 'bar',
              data,
              options: {

                responsive: true,
                title: {
                  display: true,
                  text: title,
                  fontSize: 40,
                  // padding: 30,
                },
                hover: {
                  animationDuration: 0,
                  mode: 'nearest',
                  intersect: true,
                },
                animation: {
                  // onProgress: barChartValues,
                  onComplete: () => {
                    barChartValues(myChart);
                    const image = myChart.toBase64Image();
                    myChart.destroy();
                    self.isChartProcessing = false;
                    resolve(image);
                  },
                },
                showTooltips: true,

                legend: {
                  display: true,
                  labels: {
                    fontSize: 24,
                    // padding: 20

                  },
                },
                tooltips: {
                  enabled: false,
                },
                scales: {
                  xAxes: [{
                    display: true,
                    scaleLabel: {
                      display: true,
                      fontSize: 24,
                      // padding: 5,
                    },
                    gridLines: {
                      display: true,
                    },
                    ticks: {
                      fontSize: 24,
                      // padding: 35,
                      autoSkip: false,
                    },
                  }],
                  yAxes: [{
                    display: true,
                    scaleLabel: {
                      display: false,
                      fontSize: 24,
                      labelString: 'Value',
                      // padding: 5,
                    },
                    ticks: {
                      fontSize: 24,
                      autoSkip: false,
                      // padding: 35,
                      beginAtZero: true,
                      callback(value, index, values) {
                        return dateFormat ? `${value} dk` : value;
                      },
                    },
                    gridLines: {
                      display: true,
                    },
                  }],

                },
                layout: {
                  padding: {
                    top: 50,
                    bottom: 50,
                  },
                },

                plugins: [{
                  datalabels: {
                    display: true,
                    padding: 15,
                  },
                  paddingBelowLegends: false,
                }],
              },
            });
          });
        } catch (e) {
          reject(e);
        }
      });
    },
    getMultipleAxisChart(title, label1, label2, labelData, data1, data2, xAxisLabel, type = 'horizontalBar') {
      return new Promise((resolve, reject) => {
        try {
          // document.body.innerHTML = '     <canvas style="position: relative; height:100vh; width:11vw" id="myChart"></canvas>\n';
          const chartColors = {
            red: 'rgb(255, 99, 132)',
            orange: 'rgb(255, 159, 64)',
            yellow: 'rgb(255, 205, 86)',
            green: 'rgb(75, 192, 192)',
            blue: 'rgb(54, 162, 235)',
            purple: 'rgb(153, 102, 255)',
            grey: 'rgb(231,233,237)',
          };
          const ctx = document.getElementById('myChart').getContext('2d');
          const myChart = new Chart(ctx, {
            type,
            data: {
              labels: labelData,
              datasets: [{
                label: label1,
                backgroundColor: chartColors.red,
                borderColor: chartColors.red,
                lineTension: 0,
                data: data1,
                fill: false,
              }, {
                label: label2,
                fill: false,
                backgroundColor: chartColors.blue,
                borderColor: chartColors.blue,
                lineTension: 0,
                data: data2,
              }],
            },
            options: {
              responsive: true,
              title: {
                display: true,
                text: title,
                fontSize: 50,
              },
              legend: {
                labels: {
                  fontSize: 45,
                },
              },
              tooltips: {
                mode: 'label',
              },
              hover: {
                mode: 'nearest',
                intersect: true,
              },
              scales: {
                xAxes: [{
                  display: true,
                  scaleLabel: {
                    display: true,
                    fontSize: 35,
                    labelString: `(${xAxisLabel})`,
                  },
                  gridLines: {
                    display: true,
                  },
                  ticks: {
                    fontSize: 35,
                    autoSkip: false,
                  },
                }],
                yAxes: [{
                  display: true,
                  scaleLabel: {
                    display: false,
                    fontSize: 35,
                    labelString: 'Value',
                  },
                  ticks: {
                    fontSize: 35,
                    autoSkip: false,
                  },
                  gridLines: {
                    display: false,
                  },
                }],
              },
              animation: {
                onComplete: () => {
                  resolve(myChart.toBase64Image());
                },
              },
            },
          });
        } catch (e) {
          reject(e);
        }
      });
    },
    async generatePeriodicMaintenanceReport() {
      const self = this;
      blockui.pageBlock(self);

       if (!await self.selectDate(self.periodicMaintenanceReportStartDate, self.periodicMaintenanceReportEndDate)) return;
            await this.$globalApiClient.workOrder.workOrderGetPeriodicMaintenanceReport({
              startDate: moment(self.periodicMaintenanceReportStartDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
              endDate: moment(self.periodicMaintenanceReportEndDate, 'DD.MM.YYYY').format('YYYY-MM-DD')
            }).then((res) => {
              self.periodicMaintenanceReportData = res.data;
            });

      const periodicMaintenanceColumns = [
        { key: 'Id', header: 'Id' },
        { key: 'InfrastructureName', header: self.translate('infrastructure') },
        { key: 'CapacityTypeName', header: self.translate('capacity_type') },
        { key: 'WorkGroupName', header: self.translate('work_group') },
        { key: 'Name', header: self.translate('name') },
        { key: 'DeviceName', header: self.translate('device') },
        { key: 'PeriodName', header: self.translate('maintenance_period') },
        { key: 'PlannedMaintenanceDate', header: self.translate('planned_maintenance_date') },
        { key: 'PerformedMaintenanceDate', header: self.translate('performed_maintenance_date') },
        { key: 'StatusName', header: self.translate('status') },
        { key: 'DefermentCondition', header: self.translate('deferment_condition') },
        { key: 'IsDelayed', header: self.translate('is_delayed') },
        { key: 'TimeElapsedSinceLastMaintenance', header: self.translate('time_elapsed_since_last_maintenance') },
      ];
      const periodicMaintenanceData = self.periodicMaintenanceReportData.map((x) => {
        return {
          Id: x.Id,
          InfrastructureName: x.InfrastructureName,
          CapacityTypeName: x.CapacityTypeName,
          WorkGroupName: x.WorkGroupName,
          Name: x.Name,
          DeviceName: x.DeviceName,
          PeriodName: x.PeriodName,
          PlannedMaintenanceDate: x.PlannedMaintenanceDate ? moment(x.PlannedMaintenanceDate).format('DD/MM/YYYY HH:mm') : '',
          PerformedMaintenanceDate: x.PerformedMaintenanceDate ?  moment(x.PerformedMaintenanceDate).format('DD/MM/YYYY HH:mm') : '',
          StatusName: x.StatusName,
          DefermentCondition: x.DefermentCondition,
          IsDelayed: x.IsDelayed,
          TimeElapsedSinceLastMaintenance: x.TimeElapsedSinceLastMaintenance,
        };
      });
    
      let context = [
        {
          sheetName: 'Veri',
          sheetData: periodicMaintenanceData,
          columnName:periodicMaintenanceColumns,
        }
      ];
     await this.generateReports("PeriodicMaintenanceReport", context, self.periodicMaintenanceReportStartDate,self.periodicMaintenanceReportEndDate);
      blockui.unBlockPage();
    },
    saveByteArray(reportName, byte) {
      const blob = new Blob([byte], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      if (navigator.appVersion.toString().indexOf('.NET') > 0) window.navigator.msSaveBlob(blob, reportName);
      else {
        const link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
        link.href = URL.createObjectURL(blob);
        link.download = reportName;
        link.click();
      }
    },
      async generateAllAssetReport(){
          const self = this;
          blockui.pageBlock(self);
          await this.$globalApiClient.report.reportGetAssetsReport().then( res => {
                  self.allAssetReportData = res.data;
          });
          var columns = [
              {key:"Id",header:"No"},
              {key:"Name",header:self.translate("name")},
              {key:"ParentDevice",header:"Parent Device"},
              {key:"Domain",header:"Domain"},
              {key:"AssetType",header:"Asset Tipi"},
              {key:"ManufacturerName",header:"Marka"},
              {key:"ModelName",header:"Model"},
              {key:"SerialNumber",header:"Seri No"},
              {key:"IsCritical",header:"Critical"},
              {key:"AssetCapacity",header:"Kapasite"},
              {key:"ProductionYear",header:"Üretim Yılı"},
              {key:"DateOfInstallation",header:"Kurulum Tarihi"},
              {key:"Location",header:"Lokasyon"},
              {key:"GuaranteeStartYear",header:"Garanti Başlangıç Tarihi"},
              {key:"GuaranteeExpireYear",header:"Garanti Bitiş Tarihi"},
              {key:"MaintenancePeriod",header:"Önerilen Bakım Aralığı"},
              {key:"MOP",header:"MOP"},
              {key:"SOP",header:"SOP"},
              {key:"MaintenanceContractStartDate",header:"Bakım Anlaşma Başlangıç Tarihi"},
              {key:"MaintenanceContractExpireDate",header:"Bakım Anlaşma Bitiş Tarihi"},
              {key:"MaintenanceRenewalRemainingDay",header:"Bakım Anlaşma Yenileme Kalan Gün"},
              {key:"ApproximateLifeTime",header:"Yaklaşık Ömür"},
              {key:"ApproximateRemainingYear",header:"Yaklaşık Kalan Ömür (yıl)"},
          ];
          var dateMoment = new Date();
          var workbook = new ExcelJS.Workbook();
          workbook.creator = 'CFM';
          workbook.created = dateMoment;
          workbook.calcProperties.fullCalcOnLoad = true;
          workbook.views = [
              {
                  x: 0, y: 0, width: 10000, height: 20000,
                  firstSheet: 0, activeTab: 1, visibility: 'visible'
              }
          ];
          let maintenanceContractExpireDate;
          var sheet = workbook.addWorksheet("Data", {
              headerFooter:{firstHeader: "Hello Exceljs", firstFooter: "Hello World"}
          });
          sheet.columns = columns;
          sheet.columns.forEach(function (column, i) {
              var maxLength = 0;
              column["eachCell"]({ includeEmpty: true }, function (cell) {
                  var columnLength = cell.value ? cell.value.toString().length : 10;
                  if (columnLength > maxLength ) {
                      maxLength = columnLength;
                  }
              });
              column.width = maxLength < 10 ? 10 : maxLength;
          });
          for (let index = 0; index < self.allAssetReportData.length; index++) {
              const element = self.allAssetReportData[index];
              if (element.IsCritical) {
                  element.IsCritical = "Critical"
              }
              else{
                  element.IsCritical = "Not Critical"
              }
              sheet.addRow(element);
          }
          sheet.eachRow({ includeEmpty: true }, function(row, rowNumber){
              row.eachCell(function(cell, colNumber){
                  if (rowNumber == 1) {
                      cell.font = {
                          name: 'Calibri',
                          family: 1,
                          bold: true,
                          size: 11,
                      };
                      cell.alignment = {
                          vertical: 'middle', horizontal: 'center'
                      };
                      for (var i = 1; i < sheet.columns.length + 1; i++) {
                          if (i<5) {
                              row.getCell(i).fill = {
                                  type: 'pattern',
                                  pattern:'solid',
                                  fgColor:{argb:'17cbcf'}
                              };
                          }
                          else if (i <14 && i >3) {
                              row.getCell(i).fill = {
                                  type: 'pattern',
                                  pattern:'solid',
                                  fgColor:{argb:'8a8ef2'}
                              };
                          }
                          else if (i >13 && i < 19) {
                              row.getCell(i).fill = {
                                  type: 'pattern',
                                  pattern:'solid',
                                  fgColor:{argb:'a2a823'}
                              };
                          }
                          else {
                              row.getCell(i).fill = {
                                  type: 'pattern',
                                  pattern:'solid',
                                  fgColor:{argb:'23a894'}
                              };
                          }
                      }
                  }else{
                      cell.font = {
                          name: 'Calibri',
                          family: 1,
                          bold: false,
                          size: 10,
                      };
                      cell.alignment = {
                          vertical: 'middle', horizontal: 'center'
                      };
                  }
              });
          });
          await this.generateReports("AssetsReport_", undefined, undefined, undefined, workbook);
          blockui.unBlockPage()
      },
      async generateVendorPerformanceReport() {
        const self = this;
        blockui.pageBlock(self);

        if (!await self.selectDate(self.vendorPerformanceReportStartDate, self.vendorPerformanceReportEndDate)) return;
      
        await this.$globalApiClient.report.reportGetVendorWorkTimeReport({
          startDate: moment(self.vendorPerformanceReportStartDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
          endDate: moment(self.vendorPerformanceReportEndDate, 'DD.MM.YYYY').format('YYYY-MM-DD')
        }).then((res) => {
          self.vendorPerformanceReportData = res.data;
        });
      
        const vendorPerformanceColumns = [
          { key: "OrderType", header: this.translate('type'), width: 10 },
          { key: "VendorName", header: this.translate('company'), width: 15 },
          { key: "UserName", header: this.translate('vendor'), width: 15 },
          { key: "VendorStartDate", header: this.translate('start_date'), width: 20 },
          { key: "VendorEndDate", header: this.translate('end_date'), width: 20 },
          { key: "VendorSpentTime", header: this.translate('time_spent_minutes'), width: 15 },
          { key: "InventoryName", header: this.translate('inventory'), width: 15 },
        ];

        const vendorPerformanceData = self.vendorPerformanceReportData.map((x) => {
          return {
            OrderType: x.OrderType,
            VendorName: x.VendorName,
            UserName: x.UserName,
            VendorStartDate: x.VendorStartDate ? moment(x.VendorStartDate).format('YYYY-MM-DD HH:mm') : '',
            VendorEndDate: x.VendorEndDate ? moment(x.VendorEndDate).format('YYYY-MM-DD HH:mm') : '',
            VendorSpentTime: x.VendorSpentTime,
            InventoryName: x.InventoryName,
          };
        });

        let context = [
          {
            sheetName: 'Veri',
            sheetData: vendorPerformanceData,
            columnName:vendorPerformanceColumns,
          }
        ];
        await this.generateReports("VendorPerformanceReport", context, self.vendorPerformanceReportStartDate,self.vendorPerformanceReportEndDate);
        blockui.unBlockPage();
      },
  },
  mounted() {
    const self = this;
    api.getLanguages().then((response) => {
      self.languages = response.body;
    });
    this.selectedLanguage = localStorage.getItem('vue-lang');

    api.getCampusWithParents().then((response) => {
      response.body.forEach((campus) => {
        self.campusList[campus.Id] = campus;
      });
      self.campuses = response.body.map((x) => ({ Text: x.Name, Value: x.Id }));
    });

    this.selectedCampusId = localStorage.getItem('campus-id');

    $("#m_header_nav [name='language']").on('change', (e) => {
      const { value } = e.target;
      if (value) {
        localStorage.setItem('vue-lang', value);
        const path = self.$router.history.current.fullPath;
        self.$router.push({ path: '/404' });
        self.$nextTick(() => {
          self.$router.replace({ path });
          // #758 no'lu bug dolayısıyla kapatıldı.
          // TODO : Eren - sorunu çözmek lazım kapatmak değil.
          // self.$router.go({path: path,force:true});
          self.$router.go();
        });
      }
    });
  },
  computed: {
    userName() {
      return localStorage.getItem('userName');
    },
    eMail() {
      return localStorage.getItem('eMail');
    },
    reportChartWidthPixel() {
      return this.reportChartWidth ? (`${this.reportChartWidth}px`) : '0';
    },
    reportChartHeightPixel() {
      return this.reportChartHeight ? (`${this.reportChartHeight}px`) : '0';
    },
  },
};
