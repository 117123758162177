<template>
    <div :id="gridNodeId" class="grid-stack-item relative" :data-gs-id="model.id" :data-gs-x="model.x"
         :data-gs-y="model.y" :data-gs-width="model.w" :data-gs-height="model.h" :data-gs-min-height="model.minH"
         :data-gs-max-height="model.maxH" :data-gs-min-width="model.minW" :data-gs-max-width="model.maxW" :key="i">
        <component :is="item.comp" :height="height" :h="model.h" :w="model.w" :x="model.x" :y="model.y"
                   :i="calcIndex" :moved="moved" @redrawn="moved=false" :chartName="item.chartName"
                   :filterType="item.filterType" :filterParameters="model.filterParameters"
                   :gridItemId="item.id"
                   @filterParametersUpdated="filterParametersUpdated"></component>
    </div>
</template>

<script>
import WorkOrderCountByStatusChart from './WorkOrderCountByStatusChart';
import WorkOrderCountCalendarChart from './WorkOrderCountCalendarChart';
import ShiftStaffCountByDayChart from './ShiftStaffCountByDayChart';
import UsersNeedToBeGetTrainingChart from './UsersNeedToBeGetTrainingChart';
import NumberOfTrainingsToBeTaken from './NumberOfTrainingsToBeTaken';
import UserWorkOrderShiftChart from './UserWorkOrderShiftChart';
import CapacityEquipmentsAndDevices from './CapacityEquipmentsAndDevices';
import PolarAreaChart from './PolarAreaChart';
import BarChart from './BarChart';
import BarChart2 from './BarChart2';
import LineChart from './LineChart';
import RadarChart from './RadarChart';
import RadarChart2 from './RadarChart2';
import BubbleChart from './BubbleChart';
import PieChart from './PieChart';
import UsersWhoNeedTheMostTrainingChart from './UsersWhoNeedTheMostTrainingChart';
import MostNeededTrainings from './MostNeededTrainings';
import MostInvalidRounds from './MostInvalidRounds';
import MostInvalidCapacityEquipments from './MostInvalidCapacityEquipments';
import StaffWhoMadeMostRound from './StaffWhoMadeMostRound';
import NumberOfCapacityEquipmentWithoutSCPFormChart from './NumberOfCapacityEquipmentWithoutSCPFormChart';
import MostRepetitiveProblemInventoryAndPoint from './MostRepetitiveProblemInventoryAndPoint';
import NumberOfTotalCapacityEquipmentChart from './NumberOfTotalCapacityEquipmentChart';
import ContractDashboardUpcomingExpire from './ContractDashboardUpcomingExpire';

export default {
  name: 'griditem',
  props: ['item', 'i', 'height'],
  components: {
    'work-order-count-by-status-chart': WorkOrderCountByStatusChart,
    'work-order-count-calendar-chart': WorkOrderCountCalendarChart,
    'shift-staff-count-by-day-chart': ShiftStaffCountByDayChart,
    'users-need-to-be-get-training-chart': UsersNeedToBeGetTrainingChart,
    'number-of-trainings-to-be-taken': NumberOfTrainingsToBeTaken,
    'number-of-capacity-equipment-without-scp-form-chart': NumberOfCapacityEquipmentWithoutSCPFormChart,
    'user-work-order-shift-chart': UserWorkOrderShiftChart,
    'capacity-equipments-and-devices': CapacityEquipmentsAndDevices,
    'polar-area-chart': PolarAreaChart,
    'pie-chart': PieChart,
    'bar-chart': BarChart,
    'bar-chart-2': BarChart2,
    'line-chart': LineChart,
    'radar-chart': RadarChart,
    'radar-chart-2': RadarChart2,
    'bubble-chart': BubbleChart,
    'users-who-need-the-most-training-chart': UsersWhoNeedTheMostTrainingChart,
    'most-needed-trainings': MostNeededTrainings,
    'most-invalid-rounds': MostInvalidRounds,
    'most-invalid-capacity-equipments': MostInvalidCapacityEquipments,
    'staff-who-made-most-round': StaffWhoMadeMostRound,
    'most-repetitive-problem-inventory-and-point': MostRepetitiveProblemInventoryAndPoint,
    'number-of-total-capacity-equipment-chart': NumberOfTotalCapacityEquipmentChart,
    'contract-dashboard-upcoming-expire': ContractDashboardUpcomingExpire,
  },
  data() {
    return {
      model: {
        x: 0,
        y: 0,
        h: 0,
        w: 0,
        i: 0,
        filterParameters: {},
      },
      calcIndex: null,
      moved: false,
    };
  },
  computed: {
    gridNodeId() {
      return `grid-stack-item-${this.item.chartName}`;
    },
  },
  methods: {
    redraw() {
    },
    filterParametersUpdated(parameters) {
      this.$emit('filterParametersUpdated', parameters);
    },
    respondToItemMove({ id, item }) {
      if (this.shouldRespondToEvent(id)) {
        this.moved = true;
        this.model.h = item.height;
        this.model.w = item.width;
        this.model.x = item.x;
        this.model.y = item.y;
      }
    },
    shouldRespondToEvent(id) {
      return id == this.item.id;
    },
    reCalcIndexes(obj) {
      this.calcIndex = obj[this.item.id] ? obj[this.item.id] : 0;
    },
  },
  created() {
    this.calcIndex = this.i;
    this.$eventHub.$on('item-moved', this.respondToItemMove);
    this.$eventHub.$on('re-indexed-items', this.reCalcIndexes);
    const temp = { ...this.item };
    if (this.item.filterType == 3) {
      Object.assign(temp, {
        filterParameters: {
          startDate: moment().startOf('week').toDate(),
          endDate: moment().endOf('week').toDate(),
        },
      });
    }
    this.model = temp;
  },
  mounted() {
  },
  beforeDestroy() {
    this.$eventHub.$off('item-moved');
    this.$eventHub.$off('re-indexed-items');
  },
};
</script>
<style>
</style>
