


























































































































































































































































































































import CFMDialog from '@/scripts/CFMDialog'
import {Vue, Component} from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'

import CFMMultiselect from '@/components/partials/CFMMultiselect.vue'
import BootstrapSwitchComponent from '@/components/shared/BootstrapSwitchComponent';
import MinutePicker from '@/components/shared/minutepicker.vue';

import * as dateFormat from '@/helpers/dateFormat';
import * as swal from '@/helpers/swal'

import {required, requiredIf, minValue} from "vuelidate/lib/validators";

import { CFMDTOWorkOrder, CFMModelWorkWithBreadCrumbView, CFMCoreContractListComboSource, CFMCoreEntityComboSource, CFMDTOMaintenancePlan, CFMDTOMaintenancePlanUpdate, CFMDTOWorkForMaintenancePlan, CFMDTOContract } from '@/scripts/services/ClientApiAuto'
import { mixinz } from '@/scripts/global/mixinz';

@Component<MaintenancePlanDialog>({
    data() {
      return {
        selectedMaintenancePeriod: 6,
        value: 1,
        selectedMonthlyDays: [],
        monthlyDaysList: Array.from({ length: 31 }, (_, index) => ({ id: index + 1, value: index + 1 })),
        selectedDays: [],
        repeatedDays: 1
      }
    },
    
    methods: {
      selectedMaintenance() {
        this.$v.$reset();
        this.repeatedDays = 1;
      }
    },
    
    components: {
        'cfm-multiselect': CFMMultiselect,
        'bootstrap-switch-component': BootstrapSwitchComponent,
        'minutepicker': MinutePicker,
    },

    validations() {
      return {
        startDate:{required},
        maintenancePlan:{
          OrderTypeId: {
            required
          },
          Duration: {
            required,
            minValue: minValue(1),
          },
          VendorId: {
            // @ts-ignore
            requiredIf: requiredIf(() => this.sendMail)
          },
          VendorNotificationRemainingDay: {
            // @ts-ignore
            requiredIf: requiredIf(() => this.sendMail),
            minValue: (value:any) => !this.sendMail || value > 0,
          },
          // Metin => (#26182) talep nedeniyle kapasite ekipmanı seçimi kaldırıldı.
          // DeviceId: {
          //   // @ts-ignore
          //   requiredIf: requiredIf(() => !this.isUpdate && this.capacityEquipmentList!==null && this.capacityEquipmentList!==null),
          //   minValue: (value:any) => this.isUpdate || this.capacityEquipmentList===null || this.capacityEquipmentList!==null || value > 0,
          // }
        },
        workId: {
          // @ts-ignore
          requiredIf: requiredIf(() => !this.isUpdate)
        },
			  selectedDays: {
          // @ts-ignore
          requiredIf: requiredIf(() => this.selectedMaintenancePeriod == 5 && !this.isUpdate)
          },
        selectedMonthlyDays: {
          // @ts-ignore
          requiredIf: requiredIf(() => this.selectedMaintenancePeriod == 1 && !this.isUpdate)
        },  
        maintenanceCount: {
          // @ts-ignore
          requiredIf: requiredIf(() => !this.isUpdate),
          minValue: (value:any) => this.isUpdate || value > 0,
        },
      }
    },
    mixins: [mixinz]

    
})
export default class MaintenancePlanDialog extends CFMDialog {
    maintenancePlan: CFMDTOWorkOrder = {Name: '', Duration: 0, DeviceId: null};
    selectedMaintenance: any;
    value: null | number = null;
    fullWorkList : CFMDTOWorkForMaintenancePlan[]= [];
    selectedDays: number[] = [];
    repeatedDays: null | number = null;
    selectedMonthlyDays: number[] = [];
    workList : CFMCoreEntityComboSource[] = [];
    contractList : CFMCoreContractListComboSource[] = [];
    vendorList : {Name:string, Id:number}[] = [];
    maintenancePeriodList : CFMCoreEntityComboSource[] = [];
    maintenanceTypeList : CFMCoreEntityComboSource[] = [];
    //Metin => (#26182) talep nedeniyle kapasite ekipmanı seçimi kaldırıldı.
    //capacityEquipmentList : null | CFMCoreEntityComboSource[] = null;

    startDate: null | Date = null;
    maintenanceCount: number = 1;
    selectedMaintenancePeriod: null | number = null;
    workId: null | number = null;

    contract: CFMCoreContractListComboSource | null = null;
   sendMail=false;

    async created(){
      blockui.blockModal(this);
      if(this.isUpdate){
        const res = await this.apiClient.maintenancePlan.maintenancePlanGetById({id: <number>this.id});
        this.maintenancePlan = res.data;
        this.sendMail = this.maintenancePlan.VendorNotificationRemainingDay != undefined && this.maintenancePlan.VendorNotificationRemainingDay != null ? true : false;
        if(this.maintenancePlan.WorkId){
          this.getContracts(this.maintenancePlan.WorkId);
          
        }
      }
      const promises = [
        this.getWorks(),
        this.getVendors(),
        this.getMaintenanceTypes(),
      ];
      if(this.isUpdate){
        if (this.maintenancePlan.ScheduledTime) {
          // @ts-ignore
          this.startDate = moment(this.maintenancePlan.ScheduledTime).toDate();          
        }
      }
      await Promise.all(promises);
      
      blockui.unBlockModal();
      
    }
    async onWorkChanged(newValue: null | {Value: number, Text: string}){
      if(newValue) {
        const selectedWork = this.fullWorkList.find(x=>x.Id == newValue.Value);
        
        await this.getContracts(newValue.Value);
        
        this.maintenancePlan.Duration = selectedWork && selectedWork.Duration ? selectedWork.Duration : this.maintenancePlan.Duration
        this.maintenancePlan.DeviceId = null;
        //Metin => (#26182) talep nedeniyle kapasite ekipmanı seçimi kaldırıldı.
        //this.capacityEquipmentList = [];
        //this.capacityEquipmentList = null;
        // if(selectedWork && !!selectedWork.ModelId){
        //   blockui.blockElement(this, this.$el);
        //   await this.getCapacityEquipments(selectedWork.ModelId);
        //   blockui.unBlockElement(this.$el);
        // }else{
        //   this.capacityEquipmentList = null;
        // }
      } else {
        this.maintenancePlan.DeviceId = null;
        //Metin => (#26182) talep nedeniyle kapasite ekipmanı seçimi kaldırıldı.
        //this.capacityEquipmentList = null;
      }
    }

    async getWorks(){
      const response = await this.apiClient.work.workGetListForMaintenancePlan();
      this.fullWorkList = response.data;
      this.workList = response.data.map(x=>({Text:x.BreadCrumb?`${x.BreadCrumb} | ${x.Name}` : x.Name, Value:x.Id}));
    }
    async getVendors(){
      const response = await this.apiClient.vendor.vendorGetVendors();
      this.vendorList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getMaintenanceTypes(){
      const response = await this.apiClient.workOrderType.workOrderTypeGetWorkOrderTypes();
      this.maintenanceTypeList = response.data;
    }
    async getContracts(workId:number){
      var contractListResult = await this.apiClient.contract.contractGetListByWorkId({workId : workId});
      if(contractListResult.data.IsSuccess && contractListResult.data.Data)
      this.contractList = contractListResult.data.Data.map(x=>(
        {
          Text:x.Name + `\n(${dateFormat.viewDateFormat(x.StartDate)} - ${dateFormat.viewDateFormat(x.EndDate)})`, 
          Value: x.Id, 
          StartDate: x.StartDate,
          EndDate: x.EndDate
        }
      ))
    }
    //Metin => (#26182) talep nedeniyle kapasite ekipmanı seçimi kaldırıldı.
    // async getCapacityEquipments(modelId: number){
    //   const response = await this.apiClient.capacityEquipment.capacityEquipmentGetCapacityEquipmentsBreadCrumb({ modelId: modelId });
    //   this.capacityEquipmentList = response.data;
    // }

    get StartDate(){
      if (this.startDate) {
        // @ts-ignore
        return moment(this.startDate).format(this.pickerDateTimeFormat);
      }
      return null;
    }
    set StartDate(val){
      if (val) {
        // @ts-ignore
        Vue.set(this, 'startDate', moment(val, this.pickerDateTimeFormat).toDate());
      } else {
        Vue.set(this, 'startDate', val);
      }
    }
     
    //Metin => (#26182) talep nedeniyle kapasite ekipmanı seçimi kaldırıldı.
    // get selectedDevice() {
    //   // @ts-ignore
    //   return this.selectGet('capacityEquipmentList', 'maintenancePlan.DeviceId', 'Value', 'Text');
    // }
    // set selectedDevice(newValue) {
    //   // @ts-ignore
    //   return this.selectSet('maintenancePlan.DeviceId', 'Value', newValue);
    // }

    get selectedContract() {
      // @ts-ignore
      return this.selectGet('contractList', 'maintenancePlan.ContractId', 'Value', 'Text');
    }

    set selectedContract(newValue){
      this.contract = newValue;
      // @ts-ignore
      return this.selectSet('maintenancePlan.ContractId', 'Value', newValue);
    }

    get selectedWork() {
      // @ts-ignore
      return this.selectGet('workList', 'workId', 'Value', 'Text');
    }
    set selectedWork(newValue) {
      // @ts-ignore
      return this.selectSet('workId', 'Value', newValue);
    }

  getMaintenanceLastPeriodDate(){
    let periodId = this.selectedMaintenancePeriod;
    // @ts-ignore
    let date = moment(this.startDate);

      if(periodId == 6) { // Daily
        date.add('d', this.maintenanceCount-1);
      } 
      
      else if (periodId == 5){ // Weekly
        let dayOfWeekLastPeriod = this.selectedDays[(this.selectedDays.length - 1)]
        date.add((this.maintenanceCount-1) * 7, 'd');
        let dayOfWeekDate = date.weekday();
        date.add((<number>dayOfWeekLastPeriod - dayOfWeekDate), 'd')
      } 
      
      else if (periodId == 1) { // Monthly
        date.add(this.maintenanceCount-1, 'M')
        let monthLastDay = date.daysInMonth();
        let nowDay = date.date();
        //@ts-ignore
        let dayOfMonthLastPeriod = this.selectedMonthlyDays.length > 0 ? this.selectedMonthlyDays.sort(function(a, b){return a - b})[(this.selectedMonthlyDays.length - 1)].value : 0;
  
        if(monthLastDay < dayOfMonthLastPeriod){
          date.add(monthLastDay - nowDay, 'd');
        } 
  
        else if (monthLastDay >= dayOfMonthLastPeriod){
          date.add(dayOfMonthLastPeriod - nowDay, 'd');
        }
      }

    return date.toDate();
  }
  
  async onSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    blockui.blockModal(this);
    if(this.isUpdate){
      // @ts-ignore
      this.contract = this.contractList.find(x=>x.Value == this.maintenancePlan.ContractId);
    }
    if(this.contract !== null && this.contract !== undefined){
        // @ts-ignore
        const date = this.isUpdate ? this.startDate : this.getMaintenanceLastPeriodDate();
      //@ts-ignore
      if(date > moment(this.contract?.EndDate).toDate()){
        const e = await swal.qaqccheck(this, this.translate('contract_control_warning_message'));
        if(!e.value){
          blockui.unBlockModal();
          return;
        }
      }
    }
    if (this.isUpdate) {
         try{
          let updateFormData : CFMDTOMaintenancePlanUpdate = {
            Id : this.maintenancePlan.Id,
            Note : this.maintenancePlan.Note,
            StartDate : dateFormat.postDateTimeFormat(<Date>this.startDate),
            Duration : this.maintenancePlan.Duration,
            MaintenanceTypeId : this.maintenancePlan.OrderTypeId,
            VendorNotificationRemainingDay : this.maintenancePlan.VendorNotificationRemainingDay,
            VendorId : this.maintenancePlan.VendorId,
            ContractId: this.maintenancePlan.ContractId
          };
          if (!this.sendMail) {
            updateFormData.VendorId = null;
            updateFormData.VendorNotificationRemainingDay = null;
          }
          
          const response  =  await this.apiClient.maintenancePlan.maintenancePlanUpdateMaintenance(updateFormData);
          if (response.data.IsSuccess) {
            toastr.success(this.translate('save_successfully'));

            // TODO : Eren - fix xyz
            // @ts-ignore
            if (this.$route.meta.xyz) {
              // @ts-ignore
              Vue.getDatatables('.maintenance_plan');
            }
            this.saveSucceeded();
            this.close();
          } else {
            const errorMessage = response.data.Message ? response.data.Message : "Error";
            toastr.error(errorMessage);
          }
        }
        catch(e){
          console.log("err",e)
          toastr.error(e);
        }
        finally{
          blockui.unBlockModal();
        }
      }
    else {
        try {
          let formData : CFMDTOMaintenancePlan = {
            Id : this.maintenancePlan.Id,
            Note : this.maintenancePlan.Note,
            StartDate : dateFormat.postDateTimeFormat(<Date>this.startDate),
            Duration : this.maintenancePlan.Duration,
            MaintenanceTypeId : this.maintenancePlan.OrderTypeId,
            VendorNotificationRemainingDay : this.maintenancePlan.VendorNotificationRemainingDay,
            VendorId : this.maintenancePlan.VendorId,
            PeriodId : this.selectedMaintenancePeriod,
            WorkId : <number>this.workId,
            MaintenanceCount : this.maintenanceCount,
            ContractId : this.maintenancePlan.ContractId,
            DaysOfWeek : this.selectedDays,
            RepeatedDays : <number>this.repeatedDays,
            //@ts-ignore
           DaysOfMonth: this.selectedMonthlyDays.map(day => day.id) 
          };
          if (!this.sendMail) {
            formData.VendorId = null;
            formData.VendorNotificationRemainingDay = null;
          }
          
          const response  =  await this.apiClient.maintenancePlan.maintenancePlanSave(formData);
          if (response.data.IsSuccess) {
            toastr.success(this.translate('save_successfully'));
            // @ts-ignore
             response.data.Message ? toastr.error(response.data.Message) : '';
            // TODO : Eren - fix xyz
            // @ts-ignore
            if (this.$route.meta.xyz) {
              // @ts-ignore
              Vue.getDatatables('.maintenance_plan');
              // @ts-ignore
              this.$parent.slc.works.MaintenancePlanCount = this.$parent.slc.works.MaintenancePlanCount ? this.$parent.slc.works.MaintenancePlanCount + this.maintenanceCount: this.maintenanceCount;
            }
            this.saveSucceeded();
            this.close();
          } else {
            const errorMessage = response.data.Message ? response.data.Message : "Error";
            toastr.error(errorMessage);
          }
        }
        catch(e){
          console.log("err",e)
          toastr.error(e);
        }
        finally{
          blockui.unBlockModal();
        }
      }
    }
}
