






























































































































































































































































































































import CFMDialog from '@/scripts/CFMDialog'
import { Component, Vue } from 'vue-property-decorator';

import upload from "@/components/shared/upload.vue";
import * as blockui from '@/helpers/block-ui'

import CFMMultiselect from '@/components/partials/CFMMultiselect.vue'
import { required } from "vuelidate/lib/validators";

import { CFMCoreEntityComboSource, CFMDTOSparePart } from '@/scripts/services/ClientApiAuto'
import BootstrapSwitchComponent from "@/components/shared/BootstrapSwitchComponent";
import { mixinz } from '@/scripts/global/mixinz';

@Component<SparePartDialog>({
  mixins: [mixinz],
  components: {
    "cfm-multiselect": CFMMultiselect,
    'bootstrap-switch-component': BootstrapSwitchComponent,
    upload: upload,
  },
  validations() {
    return {
      sparePart:{
        Name: { required },
        KindId: { required },
        UnitId: { required }
      },
      selectedSparePartCategory: {
        CategoryId: { required }
      },
      selectedSparePartSubCategory:{
        SubCategoryId: { required } // Yedek parça alt kategorisi zorunlu olsun
      },
      selectedSparePartBrand: {
        BrandId: { required }
      },                  
    };
  },
})
export default class SparePartDialog extends CFMDialog {
  drag: boolean = false;
  sparePart: CFMDTOSparePart = { Name: '' ,Id: 0 };
  spKindList : CFMCoreEntityComboSource[] = [];
  sparePartCategoryList: { Text: String; Value: Number }[] = [];
  sparePartSubCategoryList: { Text: String; Value: Number }[] = [];
  sparePartBrandList: { Text: String; Value: Number }[] = [];
  sparePartModelList: { Text: String; Value: Number }[] = [];     
  
  unitList: CFMCoreEntityComboSource[] = [];
  capacityEquipmentList: CFMCoreEntityComboSource[] = [];
  getCapacityEquipmentLists: { Text: string; Value: number }[] = [];
  sparePartDevices: CFMCoreEntityComboSource[] = [];
  
  selectedSparePartSubCategory: CFMDTOSparePart = {};
  selectedSparePartCategory: CFMDTOSparePart = {};

  selectedSparePartBrand: CFMDTOSparePart = {};
  selectedSparePartModel: CFMDTOSparePart = {};

  multiSelectProps = {
    "close-on-select": false,
    "clear-on-select": false,
    "preserve-search": true,
  };

  get selectOptions() {
    return {
      searchable: true,
      placeholder: this.translate("please_select"),
      "select-label": this.translate("select_label"),
      "deselect-label": this.translate("deselect_label"),
      "selected-label": this.translate("selected_label"),
      "show-no-results": false,
    };
  }

  get selectedCapacityEquipment() {
    if (!this.sparePartDevices) {
        return null;
    } else {
        var a = this.sparePartDevices.map(x => {
            let selectedObj = this.capacityEquipmentList.find(y => x.Value === y.Value);
            if (selectedObj) {
                return selectedObj;
            } else {
                return {};
            }
        });
        return a;
    }
  }

  async created() {
    blockui.blockModal(this);
    if (this.isUpdate) {
      await this.loadSparePartData();
    }
    await this.loadData();
    blockui.unBlockModal();
     const promises = [this.getSparePartCategories(), this.getBrands()];
    if (this.isUpdate) {
      if (this.sparePart && this.sparePart.CategoryId || this.sparePart.BrandId){
        this.selectedSparePartCategory = this.sparePart;
        this.selectedSparePartBrand = this.sparePart;
        if (this.sparePart && this.sparePart.SubCategoryId || this.sparePart.ModelId) {
          this.selectedSparePartSubCategory = this.sparePart;
          this.selectedSparePartModel = this.sparePart;
          promises.push(this.getSparePartSubCategories( <number>this.selectedSparePartSubCategory.CategoryId ));
          promises.push(this.getModels( <number>this.selectedSparePartModel.BrandId ));
        }
      }
    }    
  }

  async loadSparePartData() {
    const res = await this.apiClient.sparePart.sparePartGetById({
      id: <number>this.id,
    });
    this.sparePart = res.data;
    // @ts-ignore
    this.sparePartDevices = res.data.SparePartDevices?.map(x=>{
      return {Text: x.Device?.Name, Value: x.DeviceId};
    });
}


  async loadData() {
    await Promise.all([
      this.getCapacityEquipmentList(),
      this.getBrands(),
      this.getModels(this.sparePart.BrandId || 0),
      this.getSparePartCategories(),
      this.getSparePartSubCategories(this.sparePart.CategoryId || 0),
      this.getSparePartKinds(),
      this.getUnits(),
    ]);
  }

  onBrandChanged(args: any) {
    this.getModels(args.BrandId);
    this.selectedSparePartModel = { Name: '' };
  }

  onCategoryChanged(args: any) {
    this.getSparePartSubCategories(args.CategoryId);
    this.selectedSparePartSubCategory = { Name: '' };
  }

  removeCapacityEquipments(capacityEquipments: CFMCoreEntityComboSource) {
    if(!this.sparePartDevices) {return;}
      let deviceIndex = this.sparePartDevices.findIndex(SparePartDevice => SparePartDevice.Value == capacityEquipments.Value);
      this.sparePartDevices.splice(deviceIndex, 1);
  }  

  selectCapacityEquipments(capacityEquipments: CFMCoreEntityComboSource) {
    if (!this.sparePartDevices) {
        this.sparePartDevices = [];
    }
    (<CFMCoreEntityComboSource[]>this.sparePartDevices).push({
      ...capacityEquipments,
      Text: capacityEquipments.Text,
      Value: capacityEquipments.Value
    });
  }

  async getCapacityEquipmentList() {
    const response = await this.apiClient.capacityEquipment.capacityEquipmentGetCapacityEquipmentsBreadCrumb();
    // @ts-ignore
    this.capacityEquipmentList = response.data;
  }

  async getBrands() {
    const response = await this.apiClient.brand.brandGetBrands();
    this.sparePartBrandList = response.data.map(x => ({
      Text: <string>x.Text,
      Value: <number>x.Value
    }));
  }

  async getModels(brandId: number) {
    const response = await this.apiClient.model.modelGetModels({
      brandId: brandId,
    });
    this.sparePartModelList = response.data.map(x => ({
      Text: <string>x.Text,
      Value: <number>x.Value
    }));
  }
   async getSparePartCategories() {
    const response = await this.apiClient.sparePartCategory.sparePartCategoryGetCategories();
    this.sparePartCategoryList = response.data.map(x => ({
      Text: <string>x.Text,
      Value: <number>x.Value
    }));
  }
  async getSparePartSubCategories(categoryId: number) {
    const response = await this.apiClient.sparePartSubCategory.sparePartSubCategoryGetCategories({categoryId: categoryId});
    this.sparePartSubCategoryList = response.data.map(x => ({
      Text: <string>x.Text,
      Value: <number>x.Value
    }));
  }

  async getSparePartKinds() {
    const response = await this.apiClient.sparePartKind.sparePartKindGetKinds();
    this.spKindList = response.data;   
  }
  
  async getUnits() {
    const response = await this.apiClient.unit.unitGetUnits();
    this.unitList = response.data;     
  }

  async onSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    const formData = {
      Id: this.sparePart.Id,
      Name: this.sparePart.Name,
      BrandId: this.selectedSparePartBrand.BrandId,
      ModelId: this.selectedSparePartModel.ModelId,
      CategoryId: this.selectedSparePartCategory.CategoryId,
      SubCategoryId: this.selectedSparePartSubCategory.SubCategoryId,
      KindId: this.sparePart.KindId,
      UnitId: this.sparePart.UnitId,
      SupplyDayCount: this.sparePart.SupplyDayCount,
      Description: this.sparePart.Description,
      CriticalStockCount: this.sparePart.CriticalStockCount,
      SparePartDevices: this.sparePartDevices ? this.sparePartDevices.map((x) => ({ DeviceId: x.Value, SparePartId: this.sparePart.Id })) : []
    };
    if (this.isUpdate) {
      formData.SparePartDevices = this.sparePartDevices ? this.sparePartDevices.map((x) => ({ DeviceId: x.Value, SparePartId: this.sparePart.Id })) : [];
    }
    blockui.blockModal(this);
    try {
      const response = await this.apiClient.sparePart.sparePartSave(formData);
      if (response.data.IsSuccess) {
        toastr.success(this.translate('save_successfully'));
        this.saveSucceeded();
        this.close();
      } else {
        const errorMessage = response.data.Message ? response.data.Message : 'Error';
        toastr.error(errorMessage);
      }
    } catch (error) {
      console.error(error);
      toastr.error('An error occurred while saving.');
    } finally {
      blockui.unBlockModal();
    }
  }
}
