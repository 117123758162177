




































































































import CFMDialog from "@/scripts/CFMDialog";
import { Vue, Component, Prop } from "vue-property-decorator";

import * as blockui from "@/helpers/block-ui";

import * as dateFormat from "@/helpers/dateFormat";
import * as swal from "@/helpers/swal";

import { required, requiredIf, minValue } from "vuelidate/lib/validators";

import {
  CFMDTOWorkOrder,
  CFMCoreContractListComboSource,
  CFMCoreEntityComboSource,
  CFMDTOWorkForMaintenancePlan,
CFMDTOMaintenancePlan,
CFMModelSuitableWorkUserSP,
} from "@/scripts/services/ClientApiAuto";
import { mixinz } from "@/scripts/global/mixinz";
import { MultiSelectComponent } from "@syncfusion/ej2-vue-dropdowns";

@Component<MaintenancePlanTransformToWorkOrderDialog>({
  data() {
    return {        
    };
  },

  methods: {
  },

  components: {
    'ejs-multiselect': MultiSelectComponent
  },

  validations() {
    return {
    };
  },
  mixins: [mixinz],
})
export default class MaintenancePlanTransformToWorkOrderDialog extends CFMDialog {
  @Prop()
  selectedMaintenancePlans?:Array<CFMDTOMaintenancePlan>;
  userData:CFMModelSuitableWorkUserSP[] = []
  observerUserData:CFMModelSuitableWorkUserSP[] = [];
  fields = { text: 'DisplayName', value: 'Id', groupBy:'Role' };
  selectedForMasterUser = []
  selectedForObserverUser = []

  componentKey = 0;
  forceRerender() {
      this.componentKey += 1;
  }


  async created() {
    blockui.blockModal(this);
    const promises = [
      this.getWorkOrderUserGetSuitableWorkUsers(),
    ];
    await Promise.all(promises);
    blockui.unBlockModal();
  }

  async getWorkOrderUserGetSuitableWorkUsers() {
        let userData: CFMModelSuitableWorkUserSP[] = [];
        let observerUserData: CFMModelSuitableWorkUserSP[] = [];
        this.selectedMaintenancePlans?.forEach((item => {
            this.apiClient.workOrderUser.workOrderUserGetSuitableWorkUsers({workOrderId:item.Id}).then((response) => {
                response.data.forEach(user => {
                  if(userData.filter(e => e.Id === user.Id).length === 0){
                      // @ts-ignore
                      user.DisplayName = `${user.Name} - Trained: No`
                      userData.push(user);
                      if(user.IsAssignableToObserver){
                          // @ts-ignore
                          user.DisplayNameObserver = `${user.Name} - Trained: No`
                          observerUserData.push(user)
                      }
                  }
                  else {
                    if(user.IsAssignableToMaster){
                      if(user.IsTrainedForMaster && userData[userData.findIndex(e => e.Id === user.Id)].IsTrainedForMaster){
                        // @ts-ignore
                        user.DisplayName = `${user.Name} - Trained: Yes`
                        userData[userData.findIndex(e => e.Id === user.Id)] = user
                        this.forceRerender()
                      }else{
                        userData[userData.findIndex(e => e.Id === user.Id)].IsTrainedForMaster = false
                      }
                    }
                    if(user.IsAssignableToObserver){
                      if(observerUserData.filter(e => e.Id === user.Id).length > 0){
                        if(user.IsTrainedForObserver && observerUserData[observerUserData.findIndex(e => e.Id === user.Id)].IsTrainedForObserver){
                          // @ts-ignore
                          //observerUserData[observerUserData.findIndex(e => e.Id === user.Id)].DisplayName = `${user.Name} - Trained: Yes`
                          user.DisplayName = `${user.Name} - Trained: Yes`
                          observerUserData[observerUserData.findIndex(e => e.Id === user.Id)] = user
                          this.forceRerender()
                        }else {
                          observerUserData[observerUserData.findIndex(e => e.Id === user.Id)].IsTrainedForObserver = false
                        }
                      }
                    }
                  }
                })
            })
        }))
        this.forceRerender()
        this.userData = userData
        this.observerUserData = observerUserData
        this.forceRerender()
  }

  async onSubmit() {
    blockui.blockModal(this);
    blockui.unBlockModal();
  }

  async handleSave() {
    let shiftMismatch = false;
    let workOrderOverlap = false;

    let shiftMismatchObserver = false;
    let workOrderOverlapObserver = false;

    if(this.selectedForMasterUser.length === 0 && this.selectedForObserverUser.length === 0) {
      return;
    }

    this.selectedMaintenancePlans?.forEach((selectedMaintenancePlan) => {
        this.selectedForMasterUser.forEach((userId) => {
            let data = this.userData.filter((user) => user.Id === userId)
            if(data.length > 0){
                if(data[0].Shifts?.length === 0){
                    shiftMismatch = true;
                }else {
                    data[0].Shifts?.forEach((shift) => {
                        // @ts-ignore
                        if(!(new Date(selectedMaintenancePlan.StartDate) >= new Date(shift.StartDate) && new Date(selectedMaintenancePlan.EndDate) <= new Date(shift.EndDate))){
                            shiftMismatch = true;
                        }
                    })
                }

                if(data[0].WorkOrders && data[0].WorkOrders.length > 0){
                    data[0].WorkOrders.forEach((workOrder) => {
                        // @ts-ignore
                        if(this.isDateOverlaped({from:new Date(selectedMaintenancePlan.StartDate),to: new Date(selectedMaintenancePlan.EndDate)},{from:new Date(workOrder.StartDate),to:new Date(workOrder.EndDate)})){
                            workOrderOverlap = true;
                        }
                    })
                }
            }
        })
        this.selectedForObserverUser.forEach((userId) => {
            let data = this.observerUserData.filter((user) => user.Id === userId)
            if(data.length > 0){
                if(data[0].Shifts?.length === 0){
                    shiftMismatch = true;
                }else {
                    data[0].Shifts?.forEach((shift) => {
                        // @ts-ignore
                        if(!(new Date(selectedMaintenancePlan.StartDate) >= new Date(shift.StartDate) && new Date(selectedMaintenancePlan.EndDate) <= new Date(shift.EndDate))){
                            shiftMismatchObserver = true;
                        }
                    })
                }

                if(data[0].WorkOrders && data[0].WorkOrders.length > 0){
                    data[0].WorkOrders.forEach((workOrder) => {
                        // @ts-ignore
                        if(this.isDateOverlaped({from:new Date(selectedMaintenancePlan.StartDate),to: new Date(selectedMaintenancePlan.EndDate)},{from:new Date(workOrder.StartDate),to:new Date(workOrder.EndDate)})){
                            workOrderOverlapObserver = true;
                        }
                    })
                }
            }
        })
    })

    if(shiftMismatch){
        //@ts-ignore
        let swalResponse = await swal.check(this, "Kullanıcının vardiyası ile eşleşmemektedir", "Atanmak istenen iş emirleri, kullanıcının vardiyası ile eşleşmemektedir. Yine de devam etmek ister misin?");
        if (!swalResponse.value){
            return;
        }
    }
    if(workOrderOverlap){
        // @ts-ignore
        let swalResponse = await swal.check(this, "İş Emri Çakışmakta", "Atanmak istenen iş emirleri, kullanıcının mevcut diğer bir iş emriyle çakışmaktadır. Yine de devam etmek ister misiniz?");
        if (!swalResponse.value){
            return;
        }
    }

    if(shiftMismatchObserver){
        //@ts-ignore
        let swalResponse = await swal.check(this, "Refakatçinin vardiyası ile eşleşmemektedir", "Atanmak istenen iş emirleri, refakatçinin vardiyası ile eşleşmemektedir. Yine de devam etmek ister misin?");
        if (!swalResponse.value){
            return;
        }
    }
    if(workOrderOverlapObserver){
        // @ts-ignore
        let swalResponse = await swal.check(this, "Refakatçinin İş Emri Çakışmakta", "Atanmak istenen iş emirleri, refakatçinin mevcut diğer bir iş emriyle çakışmaktadır. Yine de devam etmek ister misiniz?");
        if (!swalResponse.value){
            return;
        }
    }

    let promisesForMaster = [];
    blockui.blockModal(this);
    //@ts-ignore
    for(let i=0;i<this.selectedMaintenancePlans?.length; i++){
        for(let j=0; j<this.selectedForMasterUser.length; j++){
          promisesForMaster.push(this.apiClient.workOrderUser.workOrderUserSave({
                // @ts-ignore
                WorkOrderId: this.selectedMaintenancePlans[i].Id,
                UserId: this.selectedForMasterUser[j],
                UserPositionId: 1
            },{forceSave:true}))
            
        }
    }
    try {
        await Promise.all(promisesForMaster);
    } catch (error) {
    }

    let promisesForObserver = [];
    // @ts-ignore
    for(let i=0;i<this.selectedMaintenancePlans?.length; i++){
      for(let j=0; j<this.selectedForObserverUser.length; j++){
        promisesForObserver.push(this.apiClient.workOrderUser.workOrderUserSave({
                // @ts-ignore
                WorkOrderId: this.selectedMaintenancePlans[i].Id,
                UserId: this.selectedForObserverUser[j],
                UserPositionId: 2
            },{forceSave:true}))
            
        }
    }
    try {
      await Promise.all(promisesForObserver);
      blockui.unBlockModal();
      this.saveSucceeded();
      this.close();
    } catch (error) {
      blockui.unBlockModal();
      this.saveSucceeded();
      this.close();
    }
  }

  isDateOverlaped = (firstDateRange:any, secondDateRange:any) => {
    // f-from -----------f-to
    //          s-from -------------- s-to
    const overlappedEnd =
        firstDateRange.from <= secondDateRange.from && firstDateRange.to >= secondDateRange.from && firstDateRange.to <= secondDateRange.to

    // f-from ----------------------- f-to
    //          s-from --------- s-to
    const overlappedBetween = firstDateRange.from <= secondDateRange.from && firstDateRange.to >= secondDateRange.to
    
    const overlappedBetween2 = secondDateRange.from <= firstDateRange.from && secondDateRange.to >= firstDateRange.to

    //            f-from -----------f-to
    // s-from -------------- s-to
    const overlappedStart =
        firstDateRange.from >= secondDateRange.from && firstDateRange.from <= secondDateRange.to && firstDateRange.to >= secondDateRange.to
        

    return overlappedEnd || overlappedBetween || overlappedStart || overlappedBetween2
}
}
