

























































































































































































































































































import CFMDialog from '@/scripts/CFMDialog'
import {Component, Prop} from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'

import {required, requiredIf} from "vuelidate/lib/validators";

import {
    CFMDTOJob,
    CFMDTOResetPasswordDTO,
    CFMDTORole,
    CFMDTOUser,
    CFMDTOUserExperience,
    CFMDTOUserType,
    CFMDTOVendor,
    CFMModelUser
} from '@/scripts/services/ClientApiAuto'
import BootstrapSwitchComponent from '@/components/shared/BootstrapSwitchComponent';
import CFMMultiselect from '@/components/partials/CFMMultiselect.vue';
import UserTraining from '@/components/partials/user/UserTraining.vue';
import UserAssessment from '@/components/partials/user/UserAssessment.vue';
import {mixinz} from '@/scripts/global/mixinz'
import * as api from "@/scripts/services/api";
import Vue from "vue";
import {namespace} from "vuex-class";
import moment from 'moment';

const enumsModule = namespace('enums')

@Component<UserDialog>({

    components:
        {
            'bootstrap-switch-component': BootstrapSwitchComponent,
            'cfm-multiselect': CFMMultiselect,
            'user-training': UserTraining,
            'user-assessment': UserAssessment,
        },

    validations() {
        return {
            user: {
                Name: {required},
                Surname: {required},
                Email: {required},
                Username: {
                    requiredIf: requiredIf(() => this.isDomainUser )
                },
            },

            resetUser: {
                NewPassword: {
                    requiredIf: requiredIf(() => this.resetPas)
                },
                NewPasswordRepeat: {
                    requiredIf: requiredIf(() => this.resetPas)
                },
            },

            selectedJob:{
                Id:{required}
            },
            selectedUserType:{
                Id:{required}
            },
            selectedExperience:{
                Id:{required}
            },
            selectedRole:{
                Id:{required}
            },
            selectedVendor: {
                Id:{
                    requiredIf: requiredIf(() => this.isVendor)
                }
            }
        }
    },
    mixins: [mixinz]
})

export default class UserDialog extends CFMDialog {
    @Prop()
    resetPas?: boolean;

    @enumsModule.State('userTypes')
    enumUserTypes!: { [key: string]: number; };

    user: CFMDTOUser = {Email: '', Name: '', Surname: '', IsSendPasswordResetNotification: true, IsDomainUser: false};
    resetUser = {Name: '', Surname: '', NewPassword: '', NewPasswordRepeat: ''}
    classObject = 'modal-dialog';

    currentUserId: any;

    jobList: { Name: string, Id: number }[] = [];
    userTypeList: { Name: string, Id: number }[] = [];
    experienceList: { Name: string, Id: number }[] = [];
    roleList: { Name: string, Id: number }[] = [];
    vendorList: { Name: string, Id: number }[] = [];


    selectedUserType: CFMDTOUserType = {Name: ''};
    selectedRole: CFMDTORole = {Name: ''};
    selectedJob: CFMDTOJob = {Name: ''};
    selectedExperience: CFMDTOUserExperience = {Name: ''};
    selectedVendor: CFMDTOVendor = {Name: ''};

    lastFoundDomainUsername: string | null = null;

    IsActiveDateTime: Date | null = null;

    userTrainingCount = 0;
    userAssessmentCount = 0;

    IsSendPasswordResetNotificationDisabled = false;

    async created() {
        this.currentUserId = 1;
        if (!this.resetPas) {
            this.classObject += ' ' + 'modal-xl'
        }
        blockui.blockModal(this);
        if (this.resetPas) {
            const res = await this.apiClient.user.userGetUserDetailsById({id: <number>this.id});
            this.resetUser.Name = res.data.Name;
            this.resetUser.Surname = res.data.Surname;
        }
        if (this.isUpdate) {
            const res = await this.apiClient.user.userGetUserDetailsById({id: <number>this.id});
            this.user = res.data;

            if (this.user.JobId && this.user.Job) {
                this.selectedJob = this.user.Job
            }
            if (this.user.RoleId && this.user.Role) {
                this.selectedRole = this.user.Role
            }
            if (this.user.UserExperienceId && this.user.UserExperience) {
                this.selectedExperience = this.user.UserExperience
            }
            if (this.user.UserTypeId && this.user.UserType) {
                this.selectedUserType = this.user.UserType
            }
            if (this.user.VendorId && this.user.Vendor) {
                this.selectedVendor = this.user.Vendor
            }
            if (this.user.IsActiveDateTime) {
                this.IsActiveDateTime = this.user.IsActiveDateTime;
            }
        }
        if (!this.resetPas) {
            const promises = [
                this.getExperiences(),
                this.getRoles(),
                this.getJobs(),
                this.getUserTypes(),
                this.getVendorList(),
            ];
            await Promise.all(promises);
        }
        blockui.unBlockModal();
    }

    onChangeUserType() {
        if(this.isVendor){
            this.user.IsSendPasswordResetNotification = false
            this.IsSendPasswordResetNotificationDisabled = true;
        }else {
            this.user.IsSendPasswordResetNotification = true
            this.IsSendPasswordResetNotificationDisabled = false;
        }
    }

    get isVendor(){
        return this.selectedUserType.Id == this.enumUserTypes.vendor;
    }

    get isDomainUser(){
        return this.isUpdate ? !!this.user.Username : this.user.IsDomainUser;
    }

    async findDomainUser() {
        blockui.blockModal(this);
        try {
            if (!this.user || !this.user.Username) {
                return;
            }
            const result = (await this.apiClient.user.userGetLdapUser({username: this.user.Username})).data;
            this.lastFoundDomainUsername = this.user.Username;
            Vue.set(this.user, 'Name', result.Name);
            Vue.set(this.user, 'Surname', result.Surname);
            Vue.set(this.user, 'Email', result.Email);
        } catch (e) {
            blockui.unBlockModal();
        }
        blockui.unBlockModal();
    }

    async getJobs() {
        const res = await this.apiClient.job.jobGetAllJobs();
        this.jobList = res.data.map(x => ({Name: <string>x.Text, Id: <number>x.Value}));
    }

    async getUserTypes() {
        const res = await this.apiClient.user.userGetUserTypes();
        this.userTypeList = res.data.map(x => ({Name: <string>x.Text, Id: <number>x.Value}));
    }

    async getExperiences() {
        const res = await this.apiClient.user.userGetUserExperiences();
        this.experienceList = res.data.map(x => ({Name: <string>x.Text, Id: <number>x.Value}));
    }

    async getRoles() {
        const res = await this.apiClient.role.roleGetRoles();
        this.roleList = res.data.map(x => ({Name: <string>x.Text, Id: <number>x.Value}));
    }

    async getVendorList() {
        const res = await this.apiClient.vendor.vendorGetVendors();
        this.vendorList = res.data.map(x => ({Name: <string>x.Text, Id: <number>x.Value}));
    }

    async updateDateTime() {
        this.IsActiveDateTime = moment().toDate();
    }
    formatDate(date: any) {
      return moment(date).format('DD.MM.YYYY HH:mm');
    }
    
    async onSubmit() {
        this.$v.$touch();
        if (this.resetPas)
        {
            if (this.$v.resetUser.$invalid){
                return;
            }
        } else {
            if (this.$v.$invalid){
                return;
            }
        }
        blockui.blockModal(this);
        if (this.resetPas) {
            try {
                const resetPasswordDTO: CFMDTOResetPasswordDTO = {
                    Id: this.id,
                    Password: this.resetUser.NewPassword,
                    PasswordConfirm: this.resetUser.NewPasswordRepeat
                };
                const response = await this.apiClient.user.userResetPasswordById(resetPasswordDTO);
                if (response.data.IsSuccess) {
                    toastr.success(this.translate('password_changed'));
                    this.saveSucceeded();
                    this.close();
                } else {
                    const errorMessage = response.data.Message ? response.data.Message : "Error";
                    toastr.error(errorMessage);
                }
            } catch (e) {
                toastr.error(e);
            } finally {
                blockui.unBlockModal();
            }
        } else {
            let formData: CFMDTOUser = {
                Id: Number(this.id) ? Number(this.id) : 0,
                Email: this.user.Email,
                Name: this.user.Name,
                Surname: this.user.Surname,
                IsActive: Number(this.id) ? this.user.IsActive : true,
                JobId: this.selectedJob.Id,
                UserTypeId: this.selectedUserType.Id,
                UserExperienceId: this.selectedExperience.Id,
                RoleId: this.selectedRole.Id,
                Phone: this.user.Phone,
                IsActiveDateTime: this.IsActiveDateTime,
                DateOfEmployment: this.user.DateOfEmployment,
                Username: this.isDomainUser ? this.lastFoundDomainUsername : null,
                IsSendPasswordResetNotification: this.user.IsSendPasswordResetNotification,
                VendorId: this.selectedUserType.Id == 3 ? this.selectedVendor.Id : null
            }
            const response = await this.apiClient.user.userSave(formData);
            if (response.data.IsSuccess) {
                toastr.success(this.translate('save_successfully'));
                this.saveSucceeded();
                this.close();
            } else {
                const errorMessage = response.data.Message ? response.data.Message : "Error";
                toastr.error(errorMessage);
                blockui.unBlockModal();
            }
        }
    }
}
