/* eslint-disable default-case */
/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable import/order */
/* eslint-disable max-len */
import {Vue, Component} from 'vue-property-decorator';
// eslint-disable-next-line import/no-extraneous-dependencies
import {Predicate} from '@syncfusion/ej2-data';
// eslint-disable-next-line object-curly-spacing
import {ODataResult, OtherApi, DataResult} from '@/scripts/services/OtherApis';
import {
    GridPlugin,
    DataStateChangeEventArgs,
    Sort,
    Sorts,
    Group,
    Page,
    Toolbar,
    Filter,
    ExcelExport,
    PdfExport,
    CommandColumn,
    Column,
    Resize
} from '@syncfusion/ej2-vue-grids';
import { PdfTrueTypeFont,PdfFontFamily,PdfStandardFont,PdfFontStyle } from '@syncfusion/ej2-pdf-export';
import {adventProFont} from '../font/font.js';
import {L10n, setCulture} from '@syncfusion/ej2-base';
import * as EJ2_LOCALE from "../../node_modules/@syncfusion/ej2-locale/src/tr.json";
import moment from 'moment';
import {ClickEventArgs} from "@syncfusion/ej2-vue-navigations";

Vue.use(GridPlugin);
L10n.load({ tr: EJ2_LOCALE.tr });
setCulture("tr");

@Component({
    provide() {
        return {
            grid: [Sort, Group, Page, Toolbar, Filter, ExcelExport, PdfExport, CommandColumn, Resize],
        };
    },
})
export default class CFMGridPage<T> extends Vue {
    gridProps: any = {
        ref: 'grid',
        allowPaging: true,
        allowFiltering: true,
        allowSorting: true,
        allowGrouping: true,
        allowResizing: true,
        allowExcelExport: true,
        allowPdfExport: true,
        allowCsvExport: true,
    }

    apiClient: OtherApi<any>;

    entityType = '';

    expandProperties: string[] = [];

    dateFormat = {format: 'dd/MM/yyyy hh:mm', type: 'datetime'};

    filterSettings: { type: string, columns?: { field: string, matchCase: boolean, operator: string, predicate: string, value: string }[] } = {type: 'Excel'};

    sorts: { field: string, direction: string }[] = [];

    sortOptions: { columns: { field: string, direction: string }[] } = {columns: []};

    state: DataStateChangeEventArgs = {};

    entityState: DataStateChangeEventArgs = {where: [new Predicate('isActive', 'equal', true, false)]};

    pageOptions = {pageSize: 10, pageCount: 4, pageSizes: true};

    gridDataSource: DataResult<T> | {} = {};

    toolbar:{text: string, tooltipText: string, prefixIcon:string, id:string}[]= [];

    selectedId: number | null = null;

    isDialogActive = false;

    whereState: any = []

    sortState: any = [];

    pagination: any = [];

    constructor() {
        super();
        this.apiClient = this.$globalApiClient;
    }

    created() {
        this.createdHook();
    }

    createdHook(){
        let sorted: Sorts[] = [];
        if (this.sorts.length > 0) {
            this.sortOptions.columns = this.sorts;
            sorted = this.sorts.map((x) => ({direction: x.direction.toLowerCase(), name: x.field}));
        }
        let where: Predicate[] = [];
        // @ts-ignore
        if (this.filterSettings.columns && this.filterSettings.columns.length > 0) {
            where = [
                {
                    isComplex: true,
                    ignoreAccent: false,
                    condition: 'and',
                    // @ts-ignore
                    predicates: this.filterSettings.columns.map((x) => ({
                        isComplex: false,
                        field: x.field,
                        operator: x.operator,
                        value: x.value,
                        ignoreCase: !x.matchCase,
                    })),
                },
            ];
        }

        this.state.skip = 0;
        this.state.take = this.pageOptions.pageSize;
        this.state.sorted = sorted;
        this.state.where = where;

        this.toolbar = [{
            text: 'Excel Export', tooltipText: 'Excel Export', prefixIcon: 'e-excelexport', id: 'ExcelExport'
        }, {
            text: 'CSV Export', tooltipText: 'CSV Export', prefixIcon: 'e-csvexport', id: 'CSVExport'
        }, {
            text: 'PDF Export', tooltipText: 'PDF Export', prefixIcon: 'e-pdfexport', id: 'PDFExport'
        }, {
            text: this.translate('add'), tooltipText: this.translate('add'), prefixIcon: 'e-add', id: 'Add',
        }, {
            text: this.translate('edit'), tooltipText: this.translate('edit'), prefixIcon: 'e-edit', id: 'Edit',
        }, {
            text: this.translate('delete'), tooltipText: this.translate('delete'), prefixIcon: 'e-delete', id: 'Delete',
        }];
    }

    customOptionsPdfExport(grid:any){
        const columns = (<any>this.$refs.grid).getColumns();
        let width = 1000 / columns.length;
        for (let index = 0; index < columns.length; index++) {
            const element = columns[index];
            element.width = width;
            if (element.field == "Commands") {
                element.visible = false;
            }
            if (element.type == 'date') {
                element.format = 'yMd'
                element.visible = true;
            }
        }
        return (<any>this.$refs.grid);
    }

    async toolbarClickBase(args: ClickEventArgs) {
        let fileName=`${this.entityType}_${moment().format("X")}`;
        switch (args.item.id) {
            case 'PDFExport':
                let gridd = <any>this.customOptionsPdfExport(<any>this.$refs.grid);
                gridd.pdfExport(
                    {
                        fileName:`${fileName}.pdf`,
                        theme: {
                            header: {font:  new PdfTrueTypeFont(adventProFont, 12) },
                            caption: { font: new PdfTrueTypeFont(adventProFont, 10) },
                            record: { font: new PdfTrueTypeFont(adventProFont, 9) }
                        },
                        pageOrientation: 'Landscape',
                    });
                break;
            case 'ExcelExport':
                (<any>this.$refs.grid).excelExport({fileName:`${fileName}.xlsx`});
                break;
            case 'CSVExport':
                (<any>this.$refs.grid).csvExport({fileName:`${fileName}.csv`});
                break;
        }
    }

    private getDateColumns() {
        return (<Column[]>(<any>this.$refs.grid).getColumns()).filter((x) => x.type == 'date' || x.type == 'datetime');
    }

    private getDateColumnByFieldName(field: string): Column | undefined {
        return (<Column[]>(<any>this.$refs.grid).getColumns()).find((x) => x.field == field);
    }

    private fixDateColumns(data: T[]): T[] {
        const dateColumns = this.getDateColumns();
        data = data.map((x) => {
            dateColumns.forEach((column) => {
                // @ts-ignore
                if (x[column.field]) {
                    // @ts-ignore
                    x[column.field] = new Date(x[column.field]);
                }
            });
            return x;
        });

        return <T[]>data;
    }

    refreshGrid() {
        this.dataStateChange(this.state);
    }

    // eslint-disable-next-line consistent-return
    public async dataStateChange(state: DataStateChangeEventArgs) {
        this.state = state;
        const isFilter = !!state && !!state.action && (state.action.requestType === 'filterchoicerequest' || <string>state.action.requestType === 'filtersearchbegin');
        if (isFilter) {
            let data = await this.getData(state, isFilter);
            data = this.fixDateColumns(data);
            state.dataSource && state.dataSource(data);
        } else {
            const data = await this.getData(state, isFilter);
            data.result = this.fixDateColumns(data.result);
            this.gridDataSource = data;
            return this.gridDataSource;
        }
    }

    public getData<A extends boolean>(
        state: DataStateChangeEventArgs,
        isFilter: A,
        paginationOnOff?: boolean,
        entityType?: string,
        entityState?: DataStateChangeEventArgs,
        expandProperties?: string[]): A extends true ? Promise<T[]> : Promise<DataResult<T>>;

    public async getData(state: DataStateChangeEventArgs,
        isFilter: boolean,
        paginationOnOff: boolean = true,
        entityType = this.entityType,
        entityState = this.entityState,
        expandProperties = this.expandProperties): Promise<DataResult<T> | T[]> {
        const params: Record<string, any> = {};
        
        if(this.filterSettings.type === "Menu") {
            if(state.action?.requestType === 'filtering'){
                state.skip = 0;
                // @ts-ignore
                this.$children[0].$el.ej2_instances[0].goToPage(1);
            }
    
            if(state.skip !== undefined && state.take !== undefined){
                this.pagination.skip = state.skip;
                this.pagination.take = state.take;
            }

            if(state.sorted !== undefined) {
                this.sortState = state.sorted;
            }
            if(state.sorted === undefined) {
                state.sorted = this.sortState;
            }
            // @ts-ignore
            if(state.action?.action === "clearFilter"){
                // @ts-ignore
                this.whereState = this.whereState.filter(wherItem => {
                    if(typeof wherItem !== "object") {
                        return wherItem;
                    }
                    let predicateInclude = false;
                    // @ts-ignore
                    wherItem.predicates && wherItem.predicates.forEach(predicatesItem => {
                        // @ts-ignore
                        if(predicatesItem.field === state.action.currentFilterColumn.field){
                            predicateInclude = true;
                        }
                        if(predicatesItem.predicates){
                            //@ts-ignore
                            predicatesItem.predicates.forEach(predicatesItem2 => {
                                // @ts-ignore
                                if(predicatesItem2.field === state.action.currentFilterColumn.field){
                                    predicateInclude = true;
                                }
                            })
                        }
                    });
                    if(!predicateInclude){
                        return wherItem;
                    }
                })
                
            }

            if (state.where === undefined) {
                state.where = [];
                if(this.whereState && this.whereState?.length > 0) {
                    state.where.push(...this.whereState);
                }
                else {
                    entityState.where && state.where.push(...entityState.where);
                }
            }
            else { // state.where !== undefined
                entityState.where && state.where.push(...entityState.where);
            }

            this.whereState = [];
            // @ts-ignore
            this.whereState.push(...this.state.where);

            // // if(state.where === undefined) {
            // //     state.where = this.whereState !== undefined ? this.whereState : []
            // // }
            // if (!state.where) {
            //     state.where = [];
            // }
            // // @ts-ignore
            // entityState.where && state.where.push(...entityState.where);
            // if(this.state.where !== undefined) {
            //     this.whereState = []
            //     this.whereState.push(...this.state.where)
            //     state.where = []
            //     state.where.push(...this.whereState)
            // }
        }
        else {
            if (!state.where) state.where = [];
            entityState.where && state.where.push(...entityState.where);
        }
         
        if (isFilter) {
            const {query} = <{ query: { distincts: string[] } }>state.action;
            const applys = [`groupby((${query.distincts.map((obj) => obj.replaceAll('.', '/')).join(',')}))`];
            if (state.where && state.where?.length) {
                applys.push(`filter(${state.where.map((obj) => `(${this.predicateToOdataFilter(<Predicate>obj)})`)
                    .reverse()
                    .join(' and ')
                })`);
            }
            state.take = 10000;
            params.$apply = `${applys.reverse().join('/')}`;
        } else if (expandProperties.length > 0) {
            params.$expand = this.getExpandPropertiesQuery(expandProperties);
            if (state.where && state.where?.length) {
                params.$filter = state.where.map((obj) => `(${this.predicateToOdataFilter(<Predicate>obj)})`)
                    .reverse()
                    .join(' and ');
            }
        } else if (state.where && state.where?.length) {
            params.$filter = state.where.map((obj) => `(${this.predicateToOdataFilter(<Predicate>obj)})`)
                .reverse()
                .join(' and ');
        }
        
        if (this.filterSettings.type === "Menu") {
            if (paginationOnOff) {
                params.$skip = state.skip ? state.skip : this.pagination.skip;
                params.$top = state.take ? state.take : this.pagination.take;
            }
            else {
                params.$skip = state.skip ? state.skip : 0;
                params.$top = state.take ? state.take : this.pagination.take;
            }
        }
        else{
            params.$skip = state.skip ? state.skip : 0;
            params.$top = state.take ? state.take : this.pagination.take;
        }

        params.$count = true;

        if (state.sorted && state.sorted.length > 0) {
            params.$orderby = state.sorted.map((sort: Sorts) => {
                const normalizedObjName = (<string>sort.name).replaceAll('.', '/');
                return sort.direction === 'descending' ? `${normalizedObjName} desc` : normalizedObjName;
            }).reverse().join(',');
        }

        const response = await this.getOdataEntityQuery(params, entityType);
        if (isFilter) {
            return <T[]>response.data;
        }
        const data = <ODataResult<T>>response.data;
        // @ts-ignore
        let keys = data.value.length > 0 ? Object.keys(data.value[0]) : []
        data.value.forEach(dataItem => {
            keys.forEach(key => {
                // @ts-expect-error
                if(dataItem[key] === null) {
                    // @ts-expect-error
                    dataItem[key] = "";
                }
            })
        })
        return {result: data.value, count: data['@odata.count']};
    }

    getOdataEntityQuery(params: Record<string, any>, entityType: string) {
        return this.apiClient.getOdataEntityQuery<T>(entityType, params);
    }

    getExpandPropertiesQuery(expandProperties: string[]): string {
        return expandProperties.map((obj) => {
            if (obj.includes('/')) {
                const splittedObjArray = obj.split('/');
                let result = splittedObjArray.length > 1 ? `$select=${splittedObjArray[splittedObjArray.length - 1]}` : `${splittedObjArray[splittedObjArray.length - 1]}`;
                for (let i = splittedObjArray.length - 2; i >= 0; i--) {
                    result = i > 0 ? `$expand=${splittedObjArray[i]}(${result})` : `${splittedObjArray[i]}(${result})`;
                }
                return result;
            }
            if (obj.includes('.')) {
                const splittedObjArray = obj.split('.');
                let result = splittedObjArray.length > 1 ? `$expand=${splittedObjArray[splittedObjArray.length - 1]}` : `${splittedObjArray[splittedObjArray.length - 1]}`;
                for (let i = splittedObjArray.length - 2; i >= 0; i--) {
                    result = i > 0 ? `$expand=${splittedObjArray[i]}(${result})` : `${splittedObjArray[i]}(${result})`;
                }
                return result;
            }
            return obj;
        }).join(',');
    }


    predicateToOdataFilter(predicate: Predicate): string {
        if (predicate.isComplex) {
            return '(' + predicate.predicates.filter((predicate) => !!predicate.predicates || predicate.value !== undefined).map((predicate) => this.predicateToOdataFilter(predicate))
                .reverse()
                .join(` ${predicate.condition} `) + ')';
        }

        let result = '';
        const normalizedField = predicate.field.replaceAll('.', '/');

        const isDateField = this.getDateColumns().findIndex((x) => x.field == predicate.field) >= 0 && moment(<any>predicate.value, moment.ISO_8601, true).isValid();

        const column = this.getDateColumnByFieldName(predicate.field)
        const isTimeField = column && column.type == 'time';
        const isNumberField = column && column.type == 'number';

        const isValueString = (typeof predicate.value === 'string' || predicate.value instanceof String) && !isDateField && !isTimeField;
        const field = isValueString && predicate.ignoreCase ? `(${normalizedField})` : normalizedField;
        let predicateValue = isValueString ? `'${predicate.value?.toString()
            .replace(/I/gm, 'ı')
            .replace(/İ/gm, 'i')
        }'` : predicate.value;
        // Workaround for filter issue with timezone
        if(isDateField){
            const momentObj = moment(<any>predicate.value, moment.ISO_8601, true)
            predicateValue = momentObj.add(-momentObj.utcOffset(),'minutes').toISOString()
        }

        switch (predicate.operator) {
            case 'startswith':
                result = `${predicate.operator}(${field},${predicateValue})`;
                break;
            case 'endswith':
                result = `${predicate.operator}(${field},${predicateValue})`;
                break;
            case 'contains':
                if (isNumberField) {
                    result = `${field} eq ${predicateValue}`;
                } else {
                    result = `${predicate.operator}(${field},${predicateValue})`;
                }
                break;
            case 'equal':
                result = `${field} eq ${predicateValue}`;
                break;
            case 'notequal':
                result = `${field} ne ${predicateValue}`;
                break;
            case 'greaterthan':
                result = `${field} gt ${predicateValue}`;
                break;
            case 'greaterthanorequal':
                result = `${field} ge ${predicateValue}`;
                break;
            case 'lessthan':
                result = `${field} lt ${predicateValue}`;
                break;
            case 'lessthanorequal':
                result = `${field} le ${predicateValue}`;
                break;
        }
        return result;
    }
}
